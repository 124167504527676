import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {ResourceType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/ResourceType'
import {
  IGetPermissionsByResourceRequestInterfaces,
} from '@toolify/server/src/controllers/PermissionController/validators/GetPermissionsByResource/types/IGetPermissionsByResourceRequestInterfaces'
import {
  IGetMembersByResourceRequestInterfaces,
} from '@toolify/server/src/controllers/PermissionController/validators/GetMembersByResource/types/IGetMembersByResourceRequestInterfaces'
import {
  IUpdatePermissionsByResourceRequestInterfaces,
} from '@toolify/server/src/controllers/PermissionController/validators/UpdatePermissionsByResource/types/IUpdatePermissionsByResourceRequestInterfaces'
import {
  IGetUserPermissionsByResourceRequestInterfaces,
} from '@toolify/server/src/controllers/PermissionController/validators/GetUserPermissionsByResource/types/IGetUserPermissionsByResourceRequestInterfaces'
import {mapUserResponseEntity} from '@toolify/client/src/modules/api/singletons/mapUserResponseEntity'

export const usePermissionApiStore = defineStore('permissionApi', {
  actions: {
    async getPermissionsByResource(
      resourceType: ResourceType,
      resourceId: string,
    ): Promise<IGetPermissionsByResourceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.get<IGetPermissionsByResourceRequestInterfaces>(`/permissions/${resourceType}/${resourceId}`)
    },

    async getUserPermissionMapByResource(
      resourceType: ResourceType,
      resourceId: string,
    ): Promise<IGetUserPermissionsByResourceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.get<IGetUserPermissionsByResourceRequestInterfaces>(`/me/permissions/${resourceType}/${resourceId}`)
    },

    async getMembersByResource(
      resourceType: ResourceType,
      resourceId: string,
    ): Promise<IGetMembersByResourceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {users} = await apiRequestStore.get<IGetMembersByResourceRequestInterfaces>(`/members/${resourceType}/${resourceId}`)
      return {
        users: users.map(user => {
          return mapUserResponseEntity(user)
        }),
      }
    },

    async putPermissionsByResource(
      resourceType: ResourceType,
      resourceId: string,
      simplePermissionItems,
    ): Promise<IUpdatePermissionsByResourceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.put<IUpdatePermissionsByResourceRequestInterfaces>(`/permissions/${resourceType}/${resourceId}`, {
        data: {
          permissions: simplePermissionItems,
        },
      })
    },
  },
})
