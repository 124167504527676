import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {
  IGetResourceItemsRequestInterfaces,
} from '@toolify/server/src/controllers/ResourceItemController/validators/GetResourceItems/types/IGetResourceItemsRequestInterfaces'
import {mapResourceItemResponseEntity} from '@toolify/client/src/modules/api/singletons/mapResourceItemResponseEntity'
import {
  IUpdateResourceItemPositionRequestInterfaces,
} from '@toolify/server/src/controllers/ResourceItemController/validators/UpdateResourceItemPosition/types/IUpdateResourceItemPositionRequestInterfaces'

export const useResourceItemApiStore = defineStore('resourceItemApi', {
  actions: {
    async getResourceItems(
      workspaceId: string,
    ): Promise<IGetResourceItemsRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {resourceItems} = await apiRequestStore.get<IGetResourceItemsRequestInterfaces>(`/workspace/${workspaceId}/resource-items`)
      return {
        resourceItems: resourceItems.map(activityItem => mapResourceItemResponseEntity(activityItem)),
      }
    },

    async updateResourceItemPosition(
      resourceItemId: string,
      position: number,
      resourceGroupId?: string,
      resourceSpaceId?: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put<IUpdateResourceItemPositionRequestInterfaces>(`/resource-item/${resourceItemId}/position`, {
        data: {
          resourceGroupId,
          position,
          resourceSpaceId,
        },
      })
    },

    async deleteResourceItem(
      resourceItemId: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.delete(`/resource-item/${resourceItemId}`)
    },

    async updateResourceItemName(
      resourceItemId: string,
      name: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put(`/resource-item/${resourceItemId}/name`, {
        data: {
          name,
        },
      })
    },
    
    async markResourceAsRead(
      resourceType: string,
      resourceId: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.post(`/resource/${resourceType}/${resourceId}/mark-as-read`)
    },

    async moveResourceItem(
      resourceGroupId: string,
      resourceSpaceId: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put<IUpdateResourceItemPositionRequestInterfaces>(`/resource-group/${resourceGroupId}/move`, {
        data: {
          resourceSpaceId,
        },
      })
    },
  },
})
