import {defineStore} from 'pinia'
import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {useAuthStore} from '../AuthStore/useAuthStore'
import {KnownError} from '@toolify/server/src/middleware/ApiResponseMiddleware/types/KnownError'
import {ApiException} from '@toolify/server/src/middleware/ApiResponseMiddleware/errors/ApiException'
import {IBaseApiRequestInterfaces} from '@toolify/server/src/types/IBaseApiRequestInterfaces'
import {IToJson} from '@toolify/client/src/types/IToJson'
import {IApiRequestStoreState} from '@toolify/client/src/stores/ApiRequestStore/types/IApiRequestStoreState'
import {IApiRequestQueueItem} from '@toolify/client/src/stores/ApiRequestStore/types/IApiRequestQueueItem'
import {ApiRequestQueueItemStatus} from '@toolify/client/src/stores/ApiRequestStore/enum/ApiRequestQueueItemStatus'
import {IApiStoreRequestOptions} from '@toolify/client/src/stores/ApiRequestStore/types/IApiStoreRequestOptions'
import {useToast} from 'vue-toast-notification'

const MAX_SIMULTANEOUS_REQUESTS = 3
const MAX_RETRY_TIME = 10 * 1000
const INITIAL_RETRY_TIME = 1000
const RETRY_TIME_ADD_AMOUNT = 1000
const toast = useToast()

export const useApiRequestStore = defineStore('apiRequest', {
  state() {
    return {
      // isNetworkAvailable: false,
      lastFailedRequestDate: null,
      retryTime: INITIAL_RETRY_TIME,
      requestQueueItems: [],
      handleRequestQueueTimeout: null,
      isToastVisible: false,
    } as IApiRequestStoreState
  },
  getters: {
    hasAnyItemFailed(state): boolean {
      // return !!state.requestQueueItems.filter(item => {
      //   return item.status === ApiRequestQueueItemStatus.Failed
      // }).length
      return !!state.lastFailedRequestDate
    },
    nextRetryDate(state) {
      if (!state.lastFailedRequestDate || !state.retryTime) {
        return null
      }
      return new Date(Number(state.lastFailedRequestDate) + state.retryTime)
    },
  },
  actions: {
    initialize() {
      window.addEventListener('online', this.onOnline)
    },

    dispose() {
      window.removeEventListener('online', this.onOnline)
    },

    onOnline() {
      this.retryTime = INITIAL_RETRY_TIME
      this.handleRequestQueue()
    },

    async get<Interfaces extends IBaseApiRequestInterfaces>(
      path: string,
      config?: AxiosRequestConfig,
      options?: IApiStoreRequestOptions,
    ): Promise<IToJson<Interfaces['response']>> {
      return await this.request<Interfaces>('get', path, config, options)
    },

    async delete<Interfaces extends IBaseApiRequestInterfaces>(
      path: string,
      config?: AxiosRequestConfig,
      options?: IApiStoreRequestOptions,
    ): Promise<IToJson<Interfaces['response']>> {
      return await this.request<Interfaces>('delete', path, config, options)
    },

    async post<Interfaces extends IBaseApiRequestInterfaces>(
      path: string,
      config?: AxiosRequestConfig,
      options?: IApiStoreRequestOptions,
    ): Promise<IToJson<Interfaces['response']>> {
      return await this.request<Interfaces>('post', path, config, options)
    },

    async put<Interfaces extends IBaseApiRequestInterfaces>(
      path: string,
      config?: AxiosRequestConfig,
      options?: IApiStoreRequestOptions,
    ): Promise<IToJson<Interfaces['response']>> {
      return await this.request<Interfaces>('put', path, config, options)
    },

    async patch<Interfaces extends IBaseApiRequestInterfaces>(
      path: string,
      config?: AxiosRequestConfig,
      options?: IApiStoreRequestOptions,
    ): Promise<IToJson<Interfaces['response']>> {
      return await this.request<Interfaces>('patch', path, config, options)
    },

    addRequestToQueue<T>(item: IApiRequestQueueItem<T>) {
      this.requestQueueItems.push(item as IApiRequestQueueItem<unknown>)
      this.requestQueueItems = this.requestQueueItems.sort((a, b) => {
        return b.options.priority - a.options.priority
      })
      this.handleRequestQueue()
    },

    handleRequestQueue() {
      clearTimeout(this.handleRequestQueueTimeout)
      const currentDate = new Date()
      if (this.nextRetryDate && this.nextRetryDate > currentDate) {
        return (this.handleRequestQueueTimeout = setTimeout(() => {
          this.handleRequestQueue()
        }, Number(this.nextRetryDate) - Number(currentDate)))
      }
      const processedItemsCount = this.requestQueueItems.filter(
        (item) => item.status === ApiRequestQueueItemStatus.Processing,
      ).length
      let maxItemsThatCanBeProcessed = MAX_SIMULTANEOUS_REQUESTS
      if (this.hasAnyItemFailed) {
        maxItemsThatCanBeProcessed = Math.min(1, maxItemsThatCanBeProcessed)
      }
      if (processedItemsCount >= maxItemsThatCanBeProcessed) {
        return
      }
      const itemsToBeProcessed = this.requestQueueItems
        .filter((item) => {
          return [
            ApiRequestQueueItemStatus.Failed,
            ApiRequestQueueItemStatus.Queued,
          ].includes(item.status)
        })
        .slice(0, maxItemsThatCanBeProcessed)
      if (!itemsToBeProcessed.length) {
        return
      }
      for (const item of itemsToBeProcessed) {
        this.setQueueItemStatus(item.id, ApiRequestQueueItemStatus.Processing)
        this.handleQueueItem(item)
      }
    },

    async handleQueueItem(item: IApiRequestQueueItem<unknown>): Promise<void> {
      const authStore = useAuthStore()
      try {
        const response = await this.makeRequest(
          item.method,
          item.path,
          item.config,
        )
        item.onResolve(response)
        this.lastFailedRequestDate = null
        this.deleteQueueItem(item.id)
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.data) {
            const res = error.response.data as {
              error: { name: KnownError; message: string; extra: unknown };
            }
            if (res.error) {
              if (res.error.name === KnownError.TOKEN_EXPIRED) {
                authStore.renewToken()
                this.setQueueItemStatus(
                  item.id,
                  ApiRequestQueueItemStatus.Queued,
                )
                this.handleRequestQueue()
                return
              }
              if (res.error.name === KnownError.INVALID_TOKEN) {
                await authStore.removeTokenWithExpiryDate()
                authStore.$patch({
                  currentUser: null,
                  isLoggedIn: false,
                })
                return this.deleteQueueItem(item.id)
              }
              if (res.error.name === KnownError.NoPermissions) {
                if(!this.isToastVisible) {
                  this.isToastVisible = true
                  toast.open({
                    message: res.error.message,
                    type: 'error',
                    duration: 5000,
                    onDismiss: () => {
                      this.isToastVisible = false
                    },
                  })
                }
              }
              if (res.error.name === KnownError.INVALID_REQUEST) {
                toast.error(res.error.message)
              }
              item.onReject(
                new ApiException(
                  res.error.name,
                  res.error.message,
                  error.response.status,
                  true,
                  res.error.extra,
                ),
              )
              this.deleteQueueItem(item.id)
              return
            }
          } else if (error.request) {
            const currentDate = new Date()
            if (!this.lastFailedRequestDate) {
              this.retryTime = INITIAL_RETRY_TIME
            } else if (
              this.nextRetryDate &&
              this.nextRetryDate <= currentDate
            ) {
              this.retryTime = Math.min(
                this.retryTime + RETRY_TIME_ADD_AMOUNT,
                MAX_RETRY_TIME,
              )
            }
            this.lastFailedRequestDate = currentDate
            this.setQueueItemStatus(item.id, ApiRequestQueueItemStatus.Failed)
            this.handleRequestQueue()
            return
          }
        }
        item.onReject(error)
        this.deleteQueueItem(item.id)
      }
    },

    deleteQueueItem(id: symbol) {
      this.requestQueueItems = this.requestQueueItems.filter(
        (item) => item.id !== id,
      )
      this.handleRequestQueue()
    },

    setQueueItemStatus(id: symbol, status: ApiRequestQueueItemStatus) {
      this.requestQueueItems = this.requestQueueItems.map((item) => {
        if (item.id !== id) {
          return item
        }
        return {
          ...item,
          status,
        }
      })
    },

    getDefaultRequestOptions(): IApiStoreRequestOptions {
      return {
        priority: 0,
      }
    },

    requestAndGetId<Interfaces extends IBaseApiRequestInterfaces>(
      method = 'get',
      path: string,
      config?: AxiosRequestConfig,
      options?: IApiStoreRequestOptions,
    ): {
      id: symbol;
      promise: Promise<IToJson<Interfaces['response']>>;
    } {
      const id = Symbol()
      const promise: Promise<IToJson<Interfaces['response']>> = new Promise(
        (resolve, reject) => {
          const onResolve = (response: IToJson<Interfaces['response']>) => {
            resolve(response)
          }
          const onReject = (error) => {
            reject(error)
          }
          this.addRequestToQueue<IToJson<Interfaces['response']>>({
            id,
            method,
            path,
            config,
            options: {
              ...this.getDefaultRequestOptions(),
              ...(options ? options : {}),
            },
            status: ApiRequestQueueItemStatus.Queued,
            onResolve,
            onReject,
          })
        },
      )
      return {
        id,
        promise,
      }
    },

    request<Interfaces extends IBaseApiRequestInterfaces>(
      method = 'get',
      path: string,
      config?: AxiosRequestConfig,
      options?: IApiStoreRequestOptions,
    ): Promise<IToJson<Interfaces['response']>> {
      const {promise} = this.requestAndGetId<Interfaces>(
        method,
        path,
        config,
        options,
      )
      return promise
    },

    async makeRequest<Interfaces extends IBaseApiRequestInterfaces>(
      method = 'get',
      path: string,
      config?: AxiosRequestConfig,
    ): Promise<IToJson<Interfaces['response']>> {
      const authStore = useAuthStore()
      if (!config) {
        config = {}
      }
      const token = await authStore.getToken()
      const currentLinkId = authStore.currentLinkId
      const axiosRequestConfig = {
        method,
        url: `${ENV.ApiHost}${path}`,
        ...(config ? config : {}),
        headers: {
          ...(token
            ? {
              Authorization: `Bearer ${token}`,
            }
            : {}),
          ...(currentLinkId
            ? {
              'X-Current-Link-Id': currentLinkId,
            }
            : {}),
          ...config.headers,
        },
      }
      const axiosResponse = await axios(axiosRequestConfig)
      const res = axiosResponse.data
      return res.data
    },
  },
})
