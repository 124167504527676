import {defineStore} from 'pinia'
import {AlertStoreButton, IAlertStoreState} from './types/IAlertStoreState'

export const useAlertStore = defineStore('errorPage', {
  state: () => {
    return {
      isOpen: false,
      message: '',
      description: '',
      color: 'success',
      duration: 1000,
      isCentered: false,
      layout: 'horizontal',
      buttons: [],
      inputValue: '',
      showInput: false,
      iconClass: '',
      iconColor: 'primary',
    } as IAlertStoreState
  },
  actions: {
    open(
      message: string,
      description:string,
      duration = 1000,
      layout:'horizontal' | 'vertical',
      showInput:boolean,
      color: 'success' | 'error' = 'success',
      iconClass:string,
      iconColor?:'primary'|'gray',
      buttons?:AlertStoreButton[],
      isCentered?:boolean,
    ) {
      this.inputValue = ''
      this.isOpen = true
      this.message = message
      this.description = description
      this.color = color
      this.buttons = buttons || []
      this.duration = duration
      this.layout = layout
      this.showInput = showInput
      this.isCentered = isCentered || false
      this.iconClass = iconClass
      this.iconColor = iconColor
      if(!duration) return
      setTimeout(() => {
        this.isOpen = false
      }, duration)
    },
    close() {
      this.isOpen = false
    },
    setInput (value: string) {
      this.inputValue = value
    },
  },
})

