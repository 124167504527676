import {INotificationTypeStrategy} from '@toolify/client/src/stores/NotificationStore/types/INotificationTypeStrategy'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {IBrowserNotification} from '@toolify/client/src/stores/NotificationStore/types/IBrowserNotification'
import newNotificationSound from '@toolify/client/src/assets/sounds/new-notification.wav'

export class UserAssignedToDatabaseItemNotificationStrategy implements INotificationTypeStrategy {
  getBrowserNotification(notification: INotificationResponseEntity<NotificationType.UserAssignedToDatabaseItem>, authorNick: string): IBrowserNotification {
    const sound = newNotificationSound
    const title = ENV.AppBrandingName
    const body = `🧑 ${authorNick} assigned you as ${notification.payload.databasePropertyTitle} on ${notification.payload.databaseItemName || 'untitled'} item`
    return {
      sound,
      title,
      body,
    }
  }
}
