import {IResponseEntity} from '@toolify/server/src/services/EntityService/types/IResponseEntity'
import {EntityType} from '@toolify/server/src/services/EntityService/enum/EntityType'
import {$$} from 'vue/macros'
import {FileVariantType} from '@toolify/server/src/models/mongoose/FileVariantModel/enum/FileVariantType'
import {
  IFileVariantResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IFileVariantResponseEntity'
import {
  IImageToImageFileConversionTypeStrategyInterfaces,
} from '@toolify/server/src/services/FileVariantService/strategies/FileConversionTypeStrategies/ImageToImageFileConversionTypeStrategy/types/IImageToImageFileConversionTypeStrategyInterfaces'

type ImageFileVariant = IFileVariantResponseEntity<IImageToImageFileConversionTypeStrategyInterfaces['payload']>

export function useEntities() {
  function getEntityNameByEntity(entity: IResponseEntity) {
    if(entity.entityType === EntityType.User) {
      return (entity as IResponseEntity<EntityType.User>).payload.nick
    }
    if(entity.entityType === EntityType.Bot) {
      return 'Bot'
    }
    return 'System'
  }

  function getEntityAvatarUrlByEntity(entity: IResponseEntity) {
    if(entity.entityType === EntityType.User) {
      return (entity as IResponseEntity<EntityType.User>).payload.completedAvatarFileVariants.find(fileVariant => {
        if(fileVariant.type !== FileVariantType.Image) {
          return false
        }
        return (fileVariant as ImageFileVariant).payload.height === 64
          && (fileVariant as ImageFileVariant).payload.width === 64
      })?.url || '/static/images/logo/Logomark_Hypris.png'
    }
    if(entity.entityType === EntityType.Bot) {
      return '/static/images/logo/Logomark_Hypris.png'
    }
    return '/static/images/logo/Logomark_Hypris.png'
  }
  return $$({
    getEntityNameByEntity,
    getEntityAvatarUrlByEntity,
  })
}
