import {defineStore, storeToRefs} from 'pinia'
import {ISocketStoreState} from './types/ISocketStoreState'
import {io} from 'socket.io-client'
import {GlobalSocketEventType} from '@toolify/server/src/services/SocketService/enum/GlobalSocketEventType'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'
import {watch} from 'vue'
import {$} from 'vue/macros'
import {KnownError} from '@toolify/server/src/middleware/ApiResponseMiddleware/types/KnownError'

export const useSocketStore = defineStore('socket', {
  state: () => {
    return {
      ping: null,
      socket: null,
      isConnected: false,
      methodsWaitingToBeInjected: [],
    } as ISocketStoreState
  },
  actions: {
    on(eventType, fn) {
      if(!this.socket) {
        return this.methodsWaitingToBeInjected.push({
          eventType,
          fn,
          action: 'on',
        })
      }
      this.socket.on(eventType, fn)
    },
    onAny(fn) {
      if(!this.socket) {
        return this.methodsWaitingToBeInjected.push({
          fn,
          action: 'onAny',
        })
      }
      this.socket.onAny(fn)
    },
    offAny(fn) {
      if(!this.socket) {
        return this.methodsWaitingToBeInjected.push({
          fn,
          action: 'offAny',
        })
      }
      this.socket.offAny(fn)
    },
    off(eventType, fn) {
      if(!this.socket) {
        return this.methodsWaitingToBeInjected.push({
          eventType,
          fn,
          action: 'off',
        })
      }
      this.socket.off(eventType, fn)
    },
    initialize() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.Cypress) {
        this.socket = io(ENV.SocketIoHost, {
          autoConnect: false,
        })
        return
      }
      const authStore = useAuthStore()
      const {isLoggedIn} = $(storeToRefs(authStore))
      const socket = this.socket = io(ENV.SocketIoHost, {
        path: ENV.SocketIoPath,
        withCredentials: true,
        reconnection: true,
        autoConnect: false,
        auth: async (callback) => {
          const token = await authStore.getToken()
          callback({
            token,
          })
        },
      })
      socket.on('connect', () => {
        this.isConnected = true
      })
      socket.on('disconnect', () => {
        this.isConnected = false
      })
      socket.on(GlobalSocketEventType.TokenAboutToExpire, async () => {
        await authStore.renewToken()
      })
      socket.on(GlobalSocketEventType.TokenExpired, async () => {
        await authStore.renewToken()
        this.connect()
      })
      socket.on('connect_error', async (err) => {
        this.isConnected = false
        if (err.message === KnownError.FORBIDDEN) {
          try {
            await authStore.renewToken()
            this.connect()
          } catch (e) {
            // Empty
          }
        }
      })
      let startDate = new Date()
      socket.on(GlobalSocketEventType.Pong, () => {
        this.ping = Number(new Date()) - Number(startDate)
      })
      for(const method of this.methodsWaitingToBeInjected) {
        if(method.action === 'onAny' || method.action === 'offAny') {
          socket[method.action](method.fn)
        } else {
          socket[method.action](method.eventType, method.fn)
        }
      }
      setInterval(() => {
        startDate = new Date()
        if(!this.isConnected) {
          return
        }
        socket.emit(GlobalSocketEventType.Ping)
      }, 1000)
      watch(() => isLoggedIn, () => {
        if(authStore.isLoggedIn) {
          this.connect()
        } else {
          this.disconnect()
        }
      }, {
        immediate: true,
      })
    },
    connect() {
      if(this.isConnected) {
        return
      }
      const socket = this.socket
      socket.connect()
    },
    disconnect() {
      if(!this.isConnected) {
        return
      }
      const socket = this.socket
      socket.disconnect()
    },
    dispose() {
      this.disconnect()
    },
  },
})
