import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IResponseResourcePath,
} from '@toolify/server/src/services/ResourceService/types/resourcePath/IResponseResourcePath'
import {mapResponseResourcePathItem} from '@toolify/client/src/modules/api/singletons/mapResponseResourcePathItem'

export function mapResponseResourcePath(resourcePath: IToJson<IResponseResourcePath>): IResponseResourcePath {
  return resourcePath.map(resourcePathItem => {
    return mapResponseResourcePathItem(resourcePathItem)
  })
}
