import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {
  ICreateWorkspaceRequestInterfaces,
} from '@toolify/server/src/controllers/WorkspaceController/validators/CreateWorkspace/types/ICreateWorkspaceRequestInterfaces'
import {
  IGetWorkspaceByNameRequestInterfaces,
} from '@toolify/server/src/controllers/WorkspaceController/validators/GetWorkspaceByName/types/IGetWorkspaceByNameRequestInterfaces'
import {WorkspaceType} from '@toolify/server/src/models/mongoose/WorkspaceModel/enum/WorkspaceType'
import {
  IUpdateWorkspaceRequestInterfaces,
} from '@toolify/server/src/controllers/WorkspaceController/validators/UpdateWorkspace/types/IUpdateWorkspaceRequestInterfaces'
import {
  IDeleteWorkspaceRequestInterfaces,
} from '@toolify/server/src/controllers/WorkspaceController/validators/DeleteWorkspace/types/IDeleteWorkspaceRequestInterfaces'

export const useWorkspaceApiStore = defineStore('workspaceApi', {
  actions: {
    async getWorkspaceByName(workspaceName: string): Promise<IGetWorkspaceByNameRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.get<IGetWorkspaceByNameRequestInterfaces>(`/workspace/${workspaceName}`)
    },

    async createWorkspace(
      name: string,
      title: string,
      type: WorkspaceType,
    ): Promise<ICreateWorkspaceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.post<ICreateWorkspaceRequestInterfaces>('/workspace', {
        data: {
          name,
          title,
          type,
        },
      })
    },

    async updateWorkspace(
      id: string,
      name: string,
      title: string,
      type: WorkspaceType,
    ): Promise<ICreateWorkspaceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.patch<IUpdateWorkspaceRequestInterfaces>(`/workspace/${id}`, {
        data: {
          name,
          title,
          type,
        },
      })
    },

    async deleteWorkspace(
      id: string,
    ): Promise<IDeleteWorkspaceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.delete<IDeleteWorkspaceRequestInterfaces>(`/workspace/${id}`)
    },

    async updateLogo(workspaceId: string, file: File|Blob): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      const formData = new FormData()
      formData.append('file', file)
      await apiRequestStore.put(`/workspace/${workspaceId}/logo`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
    },
    // async inviteUserToWorkspaceByMail(workspaceName: string, mail: string): Promise<unknown> {
    //   const apiRequestStore = useApiRequestStore()
    //   return await apiRequestStore.post(`/workspace/${workspaceName}/invite`, {
    //     data: {
    //       mail,
    //     },
    //   })
    // },
  },
})
