import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IDatabaseResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IDatabaseResponseEntity'

export function mapDatabaseResponseEntity(database: IToJson<IDatabaseResponseEntity>): IDatabaseResponseEntity {
  return {
    ...database,
    createdAt: new Date(database.createdAt),
  }
}
