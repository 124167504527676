import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {
  ILoginRequestInterfaces,
} from '@toolify/server/src/controllers/AuthController/validators/Login/types/ILoginRequestInterfaces'
import {
  IRefreshTokenRequestInterfaces,
} from '@toolify/server/src/controllers/AuthController/validators/RefreshToken/types/IRefreshTokenRequestInterfaces'
import {IRegisterRequestInterfaces} from '@toolify/server/src/controllers/AuthController/validators/Register/types/IRegisterRequestInterfaces'
import {
  IChangePasswordRequestInterfaces,
} from '@toolify/server/src/controllers/AuthController/validators/ChangePassword/types/IChangePasswordRequestInterfaces'

export const useAuthApiStore = defineStore('authApi', {
  actions: {
    async loginWithCredentials(
      login: string,
      password: string,
      captcha?: string,
    ): Promise<ILoginRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {
        token,
        expiresAt,
      } = await apiRequestStore.post<ILoginRequestInterfaces>('/auth/login', {
        data: {
          login,
          password,
          captcha,
        },
      })
      return {
        expiresAt: new Date(expiresAt),
        token: token,
      }
    },

    async register(
      name: string,
      password: string,
      mail: string,
      continuePath?: string,
    ): Promise<IRegisterRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {mail: receivedMail} = await apiRequestStore.post<IRegisterRequestInterfaces>('/auth/register', {
        data: {
          name,
          password,
          mail,
          continuePath,
        },
      })
      return {
        mail: receivedMail,
      }
    },

    async forgotPassword(
      mail: string,
      continuePath?: string,
    ): Promise<IRegisterRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {mail: receivedMail} = await apiRequestStore.post<IRegisterRequestInterfaces>('/auth/forgot-password', {
        data: {
          mail,
          continuePath,
        },
      })
      return {
        mail: receivedMail,
      }
    },

    async changePassword(
      verificationId: string,
      verificationSecret: string,
      password: string,
    ): Promise<IChangePasswordRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {output} = await apiRequestStore.put<IChangePasswordRequestInterfaces>('/auth/change-password', {
        data: {
          verificationId,
          verificationSecret,
          password,
        },
      })
      return {
        output: {
          token: output.token,
          expiresAt: new Date(output.expiresAt),
        },
      }
    },

    async refreshToken(): Promise<IRefreshTokenRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {
        token,
        expiresAt,
      } = await apiRequestStore.get<IRefreshTokenRequestInterfaces>('/auth/refresh-token', {
      }, {
        priority: 2,
      })
      return {
        expiresAt: new Date(expiresAt),
        token: token,
      }
    },
  },
})
