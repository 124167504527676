import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IFileResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IFileResponseEntity'

export function mapFileResponseEntity(
  file: IToJson<IFileResponseEntity>,
): IFileResponseEntity {
  return {
    ...file,
  }
}
