<script setup lang="ts">
import {computed} from 'vue'

const props = defineProps({
  isShort: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  isWhite: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const logoWithFirstLetterUppercase = ENV.AppBrandingName.charAt(0).toUpperCase() + ENV.AppBrandingName.slice(1)
const logoLetters = computed(() => props.isShort ? 'H.' : `${logoWithFirstLetterUppercase}.`)
</script>

<template>
  <div :class="['logo', {'is-white': isWhite, 'is-loading': isLoading, 'is-short': isShort}]">
    <span v-for="(letter, index) in logoLetters" :style="{'animation-delay': `${index * 0.05}s`}" :key="index">{{letter}}</span>
  </div>
</template>

<style scoped lang="scss">
@use '../../styles/variables' as variables;

@keyframes jumping-letter {
  0%, 25%, 45%, 100% {
    transform: translateY(0);
    filter: none;
  }
  30% {
    transform: translateY(-3px);
    filter: brightness(1.1);
  }
}

@keyframes jumping-letter-short {
  0%, 31.25%, 56.25%, 100% {
    transform: translateY(0);
    filter: none;
  }
  37.5% {
    transform: translateY(-3px);
    filter: brightness(1.1);
  }
}
@keyframes fade-in-out {
  //0%, 60%, 100% {
  //  opacity: 0.5;
  //}
  //0%, 20%, 40% {
  //  opacity: 1;
  //}
}


.logo {
  font-weight: 800;
  font-size: 24px;

  & span {
    color: map-get(variables.$colors, primary);
    display: inline-block;

    &:last-child {
      color: map-get(variables.$colors, secondary);
    }
  }

  &.is-white {
    & span {
      color: oklch(100% 0.01 245 / 0.9);

      &:last-child {
        color: hsla(0, 0%, 100%, 0.5);
      }
    }
  }

  &.is-loading {
    animation: fade-in-out 1.5s linear infinite;

    & span {
      animation: jumping-letter 1.5s linear infinite;
    }

    &.is-short {
      animation: fade-in-out 1.2s linear infinite;

      & span {
        animation: jumping-letter-short 1.2s linear infinite;
      }
    }


  }
}
</style>
