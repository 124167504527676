import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IResponseResourcePathItemUnion,
} from '@toolify/server/src/services/ResourceService/types/resourcePath/IResponseResourcePath'
import {mapResourceByType} from '@toolify/client/src/modules/api/singletons/mapResourceByType'

export function mapResponseResourcePathItem(resourcePathItem: IToJson<IResponseResourcePathItemUnion>): IResponseResourcePathItemUnion {
  const resource = resourcePathItem.resource ? mapResourceByType(resourcePathItem.resourceType, resourcePathItem.resource) : null
  return {
    resourceType: resourcePathItem.resourceType,
    resourceId: resourcePathItem.resourceId,
    resource,
  } as IResponseResourcePathItemUnion // TODO: TS: Remove "as ..."
}
