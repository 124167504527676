import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IFulfillVerificationRequestInterfaces,
} from '@toolify/server/src/controllers/VerificationController/validators/FulfillVerification/types/IFulfillVerificationRequestInterfaces'
import {
  IBaseVerificationTypeStrategyInterfaces,
} from '@toolify/server/src/services/VerificationService/types/IBaseVerificationTypeStrategyInterfaces'
import {
  IVerificationResponseEntity,
} from '@toolify/server/src/controllers/VerificationController/types/entities/IVerificationResponseEntity'
import {VerificationType} from '@toolify/server/src/models/mongoose/VerificationModel/enum/VerificationType'
import {
  IMailVerificationStrategyInterfaces,
} from '@toolify/server/src/services/VerificationService/strategies/verificationTypeStrategies/MailVerificationStrategy/types/IMailVerificationStrategyInterfaces'
import {
  ITwoFactorLoginVerificationStrategyInterfaces,
} from '@toolify/server/src/services/VerificationService/strategies/verificationTypeStrategies/TwoFactorLoginVerificationStrategy/types/ITwoFactorLoginVerificationStrategyInterfaces'

export const useVerificationApiStore = defineStore('verificationApi', {
  actions: {
    async fulfillVerification<Interfaces extends IBaseVerificationTypeStrategyInterfaces>(
      verificationId: string,
      secret: string,
    ): Promise<IFulfillVerificationRequestInterfaces<Interfaces>['response']> {
      const apiRequestStore = useApiRequestStore()
      const {verification} = await apiRequestStore.post<IFulfillVerificationRequestInterfaces<Interfaces>>(`/verification/${verificationId}/fulfill`, {
        data: {
          secret,
        },
      })
      return {
        verification: this.mapVerificationResponseEntity<Interfaces>(verification),
      }
    },

    mapVerificationResponseEntity<Interfaces extends IBaseVerificationTypeStrategyInterfaces>(verification: IToJson<IVerificationResponseEntity<Interfaces>>): IVerificationResponseEntity<Interfaces> {
      return {
        ...verification,
        output: this.mapVerificationResponseEntityOutput<Interfaces>(verification.type, verification.output),
      }
    },

    mapVerificationResponseEntityOutput<Interfaces extends IBaseVerificationTypeStrategyInterfaces>(verificationType: VerificationType, output: IToJson<Interfaces['output']>): Interfaces['output'] {
      const strategies = {
        [VerificationType.MailAddress]: (output: IToJson<IMailVerificationStrategyInterfaces['output']>): IMailVerificationStrategyInterfaces['output'] => {
          return {
            ...output,
            expiresAt: new Date(output.expiresAt),
          }
        },
        [VerificationType.TwoFactorLogin]: (output: IToJson<ITwoFactorLoginVerificationStrategyInterfaces['output']>): ITwoFactorLoginVerificationStrategyInterfaces['output'] => {
          return {
            ...output,
            expiresAt: new Date(output.expiresAt),
          }
        },
      }
      return strategies[verificationType](output)
    },
  },
})
