import {createApp} from 'vue'
import {createPinia} from 'pinia'
import '../styles/style.scss'
import '@toolify/client/src/modules/theme/strategies/default/default-theme.scss'
import '@toolify/client/src/modules/theme/strategies/dark/dark-theme.scss'
import '@toolify/client/src/modules/theme/strategies/light/light-theme.scss'
import '@toolify/client/src/modules/theme/strategies/classic-dark/classic-dark-theme.scss'
import '@toolify/client/src/modules/theme/strategies/fiord/fiord-dark-theme.scss'
import '@toolify/client/src/modules/theme/strategies/classic-light/classic-light-theme.scss'
import '@toolify/client/src/styles/colors.scss'
import App from './App.vue'
import {setupClientRoutes} from './router/setupClientRoutes'
import {setupRouter} from './router/setupRouter'
import {runPostClientSetupTasks} from './runPostClientSetupTasks'
import {setupVuePlugins} from '@toolify/client/src/setup/setupVuePlugins'
import CKEditor from '@ckeditor/ckeditor5-vue'

const vueApp = createApp(App)

const pinia = createPinia()
vueApp.use(pinia)

const routes = setupClientRoutes(pinia)
const router = setupRouter(routes)
vueApp.use(router)

vueApp.use(CKEditor)

setupVuePlugins(vueApp)

runPostClientSetupTasks(
  router,
  pinia,
  vueApp,
)

vueApp.mount('#app')
