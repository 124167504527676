import {Pinia} from 'pinia'
import {useWorkspaceStore} from '../../../stores/WorkspaceStore/useWorkspaceStore'
import {useWorkspaceApiStore} from '@toolify/client/src/stores/api/WorkspaceApiStore/useWorkspaceApiStore'
import {RouteLocationNormalized} from 'vue-router'
import {usePermissionApiStore} from '@toolify/client/src/stores/api/PermissionApiStore/usePermissionApiStore'
import {ResourceType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/ResourceType'
import {KnownError} from '@toolify/server/src/middleware/ApiResponseMiddleware/types/KnownError'
import {ApiException} from '@toolify/server/src/middleware/ApiResponseMiddleware/errors/ApiException'
import {RouteName} from '@toolify/client/src/setup/router/enum/RouteName'
import {useToast} from 'vue-toast-notification'
import UniversalCookie from 'universal-cookie'

export function ensureWorkspaceRouterHookFactory(pinia: Pinia) {
  return async function ensureWorkspaceRouterHook(to: RouteLocationNormalized) {
    const workspaceName = to.params.workspaceName

    if(workspaceName) {
      const workspaceStore = useWorkspaceStore(pinia)
      const workspaceApiStore = useWorkspaceApiStore(pinia)
      const permissionApiStore = usePermissionApiStore(pinia)
      const toast = useToast()
      const cookies = new UniversalCookie()
      cookies.set('lastWorkspaceName', workspaceName as string, {
        path: '/',
        maxAge: 1000 * 3600 * 24 * 30,
      })
      if(workspaceName === workspaceStore.currentWorkspace?.name) {
        return
      }
      try {
        const {workspace} = await workspaceApiStore.getWorkspaceByName(workspaceName as string)
        const {permissions} = await permissionApiStore.getUserPermissionMapByResource(
          ResourceType.Workspace,
          workspace.id,
        )
        if(workspace.name !== workspaceName) {
          // return to the same url with the correct workspace name
          return {
            ...to,
            params: {
              ...to.params,
              workspaceName: workspace.name,
            },
          }
        }
        workspaceStore.currentWorkspace = workspace
        workspaceStore.setCurrentWorkspacePermissionMap(permissions)
      } catch(e) {
        if(e instanceof ApiException) {
          if(e.name === KnownError.NOT_FOUND) {
            toast.open({
              type: 'warning',
              message: 'Workspace not found',
            })
            return {
              name: RouteName.ChooseWorkspace,
            }
          }
        }

      }
    }
    const requireWorkspace = to.meta?.requireWorkspace
  }
}
