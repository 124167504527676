import {ConversationType} from '@toolify/server/src/models/mongoose/MessageModel/enum/ConversationType'

import {
  IMentionedInMessageNotificationTypeInterfaces,
} from '@toolify/server/src/services/NotificationService/strategies/IMentionedInMessageNotificationTypeInterfaces'

export const GetMentionedInMessageNotificationTitleByConversationTypeMap = new Map<ConversationType,(authorNick: string, payload: IMentionedInMessageNotificationTypeInterfaces['payload']) => string>([
  [ConversationType.Workspace, (authorNick: string, payload: IMentionedInMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as IMentionedInMessageNotificationTypeInterfaces<ConversationType.Workspace>['payload']
    return `${authorNick} mentioned you in ${typedPayload.conversationNotificationPayload.workspaceConversationName}`
  }],
  [ConversationType.CloudFile, (authorNick: string, payload: IMentionedInMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as IMentionedInMessageNotificationTypeInterfaces<ConversationType.CloudFile>['payload']
    return `${authorNick} mentioned you in ${typedPayload.conversationNotificationPayload.folderName} ${typedPayload.conversationNotificationPayload.cloudItemName}`
  }],
  [ConversationType.DatabaseCell, (authorNick: string, payload: IMentionedInMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as IMentionedInMessageNotificationTypeInterfaces<ConversationType.DatabaseCell>['payload']
    return `${authorNick} mentioned you in ${typedPayload.conversationNotificationPayload.databaseItemName || 'Untitled item'} / ${typedPayload.conversationNotificationPayload.databasePropertyName}`
  }],
  [ConversationType.DatabaseFile, (authorNick: string, payload: IMentionedInMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as IMentionedInMessageNotificationTypeInterfaces<ConversationType.DatabaseFile>['payload']
    return `${authorNick} mentioned you in ${typedPayload.conversationNotificationPayload.databaseItemName || 'Untitled item'} / ${typedPayload.conversationNotificationPayload.databaseItemFileName}`
  }],
])
