import {IToJson} from '@toolify/client/src/types/IToJson'
import {IResponseEntity} from '@toolify/server/src/services/EntityService/types/IResponseEntity'
import {EntityType} from '@toolify/server/src/services/EntityService/enum/EntityType'
import {
  IEntityInterfacesByEntityType,
} from '@toolify/server/src/services/EntityService/types/IEntityInterfacesByEntityType'

export function mapAuthorResponseEntity(
  authorEntity: IToJson<IResponseEntity>,
): IResponseEntity {
  return {
    entityType: authorEntity.entityType,
    entityId: authorEntity.entityId,
    payload: authorEntity.payload as IEntityInterfacesByEntityType[EntityType]['responseEntity'],
  }
}
