import {Pinia} from 'pinia'
import {RouteName} from '@toolify/client/src/setup/router/enum/RouteName'
import {useDatabaseApiStore} from '@toolify/client/src/stores/api/DatabaseApiStore/useDatabaseApiStore'
import {RouteLocationNormalized} from 'vue-router'
import {useDatabaseViewApiStore} from '@toolify/client/src/stores/api/DatabaseViewApiStore/useDatabaseViewApiStore'

export function beforeDatabasePageRouterHookFactory(pinia: Pinia) {
  return async function beforeDatabasePageRouterHook(to: RouteLocationNormalized) {
    const databaseApiStore = useDatabaseApiStore()
    const databaseViewApiStore = useDatabaseViewApiStore()
    const [
      getDatabaseResponse,
      getDatabaseViewsResponse,
    ] = await Promise.all([
      databaseApiStore.getDatabase(to.params.databaseId as string),
      databaseViewApiStore.getViews(to.params.databaseId as string),
    ])

    const database = getDatabaseResponse.database
    const databaseViews = getDatabaseViewsResponse.databaseViews
    const defaultDatabaseView = window.localStorage.getItem(`current-view-db-${database.id}`)
    if(defaultDatabaseView) {
      // check if slice exists in databaseSlices
      const databaseViewExists = databaseViews.some(slice => slice.id === database.defaultDatabaseViewId)
      if(databaseViewExists) {
        return {
          name: RouteName.DatabaseView,
          params: {
            databaseViewId: defaultDatabaseView,
            databaseId: database.id,
            workspaceName: to.params.workspaceName,
          },
        }
      }
    }
    const firstDatabaseView = databaseViews.find(view => view.position === 0)
    return {
      name: RouteName.DatabaseView,
      params: {
        databaseViewId: firstDatabaseView?.id || databaseViews?.[0]?.id,
        databaseId: database.id,
        workspaceName: to.params.workspaceName,
      },
    }
  }
}
