import {defineStore} from 'pinia'
import {IAppUpdateStoreState} from './types/IAppUpdateStoreState'
import {IAppUpdateDownloadProgress} from 'toolify-desktop/src/main/types/IAppUpdateDownloadProgress'

export const useAppUpdateStore = defineStore('appUpdateStore', {
  state: () => {
    return {
      isUpdateAvailable: false,
      isUpdateDownloaded: false,
      updateProgress: 0,
      checkUpdateTimeout: null,
    } as IAppUpdateStoreState
  },
  getters: {
    formattedUpdateProgress(state) {
      return Math.floor(state.updateProgress * 100)
    },
    isUpdateInProgress(state) {
      return !state.isUpdateDownloaded && state.isUpdateAvailable && state.updateProgress > 0
    },
  },
  actions: {
    initialize(): void {
      if(!window.electronAPI) {
        return
      }
      window.electronAPI.onAppUpdateAvailabilityChange(this.onAppUpdateAvailabilityChange) // TODO: Check if .bind is not needed
      window.electronAPI.onAppUpdateDownloaded(this.onAppUpdateDownloaded)
      window.electronAPI.onAppUpdateDownloadProgress(this.onAppUpdateDownloadProgress)
      this.checkForUpdates()
    },

    dispose(): void {
      if(!window.electronAPI) {
        return
      }
      window.electronAPI.offAppUpdateAvailabilityChange(this.onAppUpdateAvailabilityChange)
      window.electronAPI.offAppUpdateDownloaded(this.onAppUpdateDownloaded)
      window.electronAPI.offAppUpdateDownloadProgress(this.onAppUpdateDownloadProgress)
      clearTimeout(this.checkUpdateTimeout)
    },

    async downloadAppUpdate(): Promise<void> {
      await window.electronAPI.downloadAppUpdate()
    },

    async restartAppAndUpdate(): Promise<void> {
      await window.electronAPI.restartAppAndUpdate()
    },

    onAppUpdateAvailabilityChange(isUpdateAvailable: boolean): void {
      if(this.isUpdateAvailable != isUpdateAvailable) {
        this.updateProgress = 0
      }
      this.isUpdateAvailable = isUpdateAvailable
    },
    onAppUpdateDownloaded(): void {
      this.isUpdateDownloaded = true
    },
    onAppUpdateDownloadProgress(progress: IAppUpdateDownloadProgress): void {
      this.updateProgress = progress.percent / 100
    },
    async checkForUpdates() {
      clearTimeout(this.checkUpdateTimeout)
      if(this.isUpdateAvailable) {
        return false
      }
      try {
        await window.electronAPI.checkForAppUpdates()
      } finally {
        this.checkUpdateTimeout = setTimeout(() => {
          this.checkForUpdates()
        }, 1000 * 60) // TODO: Change to 1000 * 60 * 60 on prod
      }
    },
  },
})
