import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IResourceGroupResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IResourceGroupResponseEntity'

export function mapResourceGroupResponseEntity(resourceGroup: IToJson<IResourceGroupResponseEntity>): IResourceGroupResponseEntity {
  return {
    ...resourceGroup,
  } as IResourceGroupResponseEntity // TODO: TS: Remove "as ..."
}

