import {useAuthStore} from '../../../stores/AuthStore/useAuthStore'
import {RouteName} from '../enum/RouteName'
import {Pinia} from 'pinia'
import {KnownError} from '@toolify/server/src/middleware/ApiResponseMiddleware/types/KnownError'
import {ApiException} from '@toolify/server/src/middleware/ApiResponseMiddleware/errors/ApiException'
import {RouteLocationNormalized} from 'vue-router'

export function ensureAuthRouterHookFactory(pinia: Pinia) {
  return async function ensureAuthRouterHook(to: RouteLocationNormalized) {
    const authStore = useAuthStore(pinia)
    const authMeta = to.meta?.auth
    if(!authMeta) {
      return
    }
    try {
      await authStore.initializeAuth()
    } catch(error) {
      const apiException = error as ApiException
      if(apiException.name !== KnownError.INVALID_TOKEN) {
        throw error
      }
    }
    if(!authMeta?.required) {
      return
    }
    const shouldBeLoggedIn = typeof authMeta.value === 'boolean' ? authMeta.value : true
    if(shouldBeLoggedIn === authStore.isLoggedIn) {
      return
    }
    if(shouldBeLoggedIn) {
      return {
        name: RouteName.Login,
        query: {
          continue: encodeURIComponent(to.path),
        },
      }
    }
    if(to.query.continue) {
      return decodeURIComponent(to.query.continue as string)
    }
    return {
      name: RouteName.ChooseWorkspace,
    }
  }
}
