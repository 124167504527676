import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IResourceSpaceResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IResourceSpaceResponseEntity'

export function mapResourceSpaceResponseEntity(resourceSpace: IToJson<IResourceSpaceResponseEntity>): IResourceSpaceResponseEntity {
  return {
    ...resourceSpace,
  } as IResourceSpaceResponseEntity // TODO: TS: Remove "as ..."
}

