export enum GlobalSocketEventType {
  Connection = 'connection',
  Disconnect = 'disconnect',
  Disconnecting = 'disconnecting',
  Ping = 'ping',
  Pong = 'pong',
  ConnectedClientsCount = 'connected-clients-count',
  JoinResource = 'join-resource',
  LeaveResource = 'leave-resource',
  UserJoinedResource = 'user-joined-resource',
  UserLeftResource = 'user-left-resource',
  UsersInResource = 'user-ids-in-resource',
  NotificationCreated = 'notification-created',
  UserLiveDataInResource = 'user-live-data-in-resource',
  SendUserLiveDataInResource = 'send-user-live-data-in-resource',
  UserUpdated = 'user-updated',
  ResourceLastActivityChange = 'resource-last-activity-change',
  ResourceReadAtChange = 'resource-read-at-change',
  CellCommentsReadAtChange = 'cell-comments-read-at-change',
  TokenExpired = 'token-expired',
  ActivityItemRestored = 'activity-item-restored',
  TokenAboutToExpire = 'token-about-to-expire',
  ResendToken = 'resend-token',
}
