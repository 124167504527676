import {ResourceType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/ResourceType'
import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IEntityResponseTypeByResourceType,
} from '@toolify/server/src/services/ResourceService/types/IEntityResponseTypeByResourceType'
import {mapWorkspaceResponseEntity} from '@toolify/client/src/modules/api/singletons/mapWorkspaceResponseEntity'
import {
  IWorkspaceResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IWorkspaceResponseEntity'
import {mapDatabaseResponseEntity} from '@toolify/client/src/modules/api/singletons/mapDatabaseResponseEntity'
import {
  IDatabaseResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IDatabaseResponseEntity'
import {
  mapDatabasePropertyResponseEntity,
} from '@toolify/client/src/modules/api/singletons/mapDatabasePropertyResponseEntity'
import {
  IDatabasePropertyResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IDatabasePropertyResponseEntity'
import {mapDatabaseViewResponseEntity} from '@toolify/client/src/modules/api/singletons/mapDatabaseViewResponseEntity'
import {
  IDatabaseViewResponseEntity,
} from '@toolify/server/src/controllers/DatabaseViewController/types/IDatabaseViewResponseEntity'
import {mapDatabaseItemResponseEntity} from '@toolify/client/src/modules/api/singletons/mapDatabaseItemResponseEntity'
import {
  IDatabaseItemResponseEntity,
} from '@toolify/server/src/controllers/DatabaseItemController/types/IDatabaseItemResponseEntity'

export function mapResourceByType<T extends ResourceType>(
  resourceType: T,
  resource: IToJson<IEntityResponseTypeByResourceType[T]>,
): IEntityResponseTypeByResourceType[T] {
  switch(resourceType) {
  case ResourceType.Workspace:
    return mapWorkspaceResponseEntity(resource as IToJson<IWorkspaceResponseEntity>) as IEntityResponseTypeByResourceType[T]
  case ResourceType.Database:
    return mapDatabaseResponseEntity(resource as IToJson<IDatabaseResponseEntity>) as IEntityResponseTypeByResourceType[T]
  case ResourceType.DatabaseProperty:
    return mapDatabasePropertyResponseEntity(resource as IToJson<IDatabasePropertyResponseEntity>) as IEntityResponseTypeByResourceType[T]
  case ResourceType.DatabaseView:
    return mapDatabaseViewResponseEntity(resource as IToJson<IDatabaseViewResponseEntity>) as IEntityResponseTypeByResourceType[T]
  case ResourceType.DatabaseItem:
    return mapDatabaseItemResponseEntity(resource as IToJson<IDatabaseItemResponseEntity>) as IEntityResponseTypeByResourceType[T]
  }
}
