import {
  INotificationTypeStrategyFactory,
} from '@toolify/client/src/stores/NotificationStore/types/INotificationTypeStrategyFactory'
import {INotificationTypeStrategy} from '@toolify/client/src/stores/NotificationStore/types/INotificationTypeStrategy'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {
  UserAssignedToDatabaseItemNotificationStrategy,
} from '@toolify/client/src/stores/NotificationStore/strategies/UserAssignedToDatabaseItemNotificationStrategy'
import {
  MentionedInMessageNotificationStrategy,
} from '@toolify/client/src/stores/NotificationStore/strategies/MentionedInMessageNotificationStrategy'

import {
  NewMessageNotificationStrategy,
} from '@toolify/client/src/stores/NotificationStore/strategies/NewMessageNotificationStrategy'
import {ExportedDatabaseNotificationStrategy} from '../strategies/ExportedDatabase'

export class NotificationTypeStrategyFactory implements INotificationTypeStrategyFactory {
  getStrategy(type: NotificationType): INotificationTypeStrategy {
    switch (type) {
    case NotificationType.UserAssignedToDatabaseItem:
      return new UserAssignedToDatabaseItemNotificationStrategy()
    case NotificationType.MentionedInMessage:
      return new MentionedInMessageNotificationStrategy()
    case NotificationType.NewMessage:
      return new NewMessageNotificationStrategy()
    case NotificationType.ExportedDatabase:
      return new ExportedDatabaseNotificationStrategy()
    default:
      throw new Error(`Strategy for ${type} is not implemented`)
    }
  }
}
