import {RouteRecordRaw} from 'vue-router'
import {RouteName} from './enum/RouteName'
import {Pinia} from 'pinia'
import {
  beforeVerifyMailAddressRouterHookFactory,
} from '@toolify/client/src/pages/VerifyMailAddressPage/hooks/beforeVerifyMailAddressRouterHook'
import {useRouteStore} from '@toolify/client/src/stores/RouteStore/useRouteStore'
import {
  beforeLinkPageEnterRouterHookFactory,
} from '@toolify/client/src/pages/LinkPage/hooks/beforeLinkPageEnterRouterHook'
import {
  beforeChooseWorkspaceRouterHookFactory,
} from '@toolify/client/src/pages/ChooseWorkspacePage/beforeChooseWorkspaceRouterHook'
import {beforeDatabasePageRouterHookFactory} from '@toolify/client/src/pages/DatabasePage/beforeDatabasePageRouterHook'

export function setupClientRoutes(pinia: Pinia): RouteRecordRaw[] {
  return [
    {
      path: '/share/:linkId',
      component: () => import('../../pages/LinkPage/LinkPage.vue'),
      beforeEnter: beforeLinkPageEnterRouterHookFactory(pinia),
    },
    {
      path: '/workspaces',
      component: () => import('../../views/ChooseWorkspaceView.vue'),
      meta: {
        depth: 200,
        auth: {
          required: true,
          value: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.ChooseWorkspace,
          component: () => import('../../pages/ChooseWorkspacePage/ChooseWorkspacePage.vue'),
          beforeEnter: beforeChooseWorkspaceRouterHookFactory(pinia),
          meta: {
            auth: {
              required: true,
              value: true,
            },
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('../../views/AuthenticationView.vue'),
      meta: {
        depth: 200,
        auth: {
          required: true,
          value: false,
        },
      },
      children: [
        {
          path: '/',
          name: RouteName.Login,
          component: () => import('../../pages/LoginPage/LoginPage.vue'),
        },
        {
          path: '/verify-mail-address/:verificationId',
          name: RouteName.VerifyMailAddress,
          component: () => import('../../pages/VerifyMailAddressPage/VerifyMailAddressPage.vue'),
          beforeEnter: beforeVerifyMailAddressRouterHookFactory(pinia),
        },
        {
          path: '/reset-password/:verificationId',
          name: RouteName.ResetPassword,
          component: () => import('../../pages/ResetPasswordPage/ResetPasswordPage.vue'),
        },
        {
          path: '/join',
          name: RouteName.Register,
          component: () => import('../../pages/RegisterPage/RegisterPage.vue'),
        },
        {
          path: '/forgot-password',
          name: RouteName.ForgotPassword,
          component: () => import('../../pages/ForgotPasswordPage/ForgotPasswordPage.vue'),
        },
        {
          path: '/create-workspace',
          name: RouteName.CreateWorkspace,
          component: () => import('../../pages/CreateWorkspacePage/CreateWorkspacePage.vue'),
          meta: {
            auth: {
              required: true,
              value: true,
            },
          },
        },
        {
          path: '/invitation/:invitationId-:invitationSecret',
          name: RouteName.Invitation,
          component: () => import('../../pages/WorkspaceInvitationPage/WorkspaceInvitationPage.vue'),
          meta: {
            auth: {
              required: false,
              value: false,
            },
          },
        },
      ],
    },
    {
      path: '/chat',
      component: () => import('../../views/ChatView.vue'),
      meta: {
        depth: 100,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.CreateDirectConversation,
          component: () => import('../../pages/ChatPage/ChatPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: ':directConversationId',
          name: RouteName.DirectConversation,
          component: () => import('../../pages/ChatPage/ChatPage.vue'),
          meta: {
            order: 0,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName',
      component: () => import('../../views/WorkspaceView.vue'),
      meta: {
        requireWorkspace: true,
        auth: {
          required: true,
        },
        depth: 100,
      },
      children: [
        {
          path: '',
          name: RouteName.WorkspaceHome,
          component: () => import('@toolify/client/src/pages/WorkspacePage/WorkspacePage.vue'),
        },
        {
          path: 'automation-apps',
          name: RouteName.AutomationApps,
          component: () => import('@toolify/client/src/pages/AutomationAppsPage/AutomationAppsPage.vue'),
        },
        {
          path: 'crons',
          name: RouteName.Crons,
          component: () => import('@toolify/client/src/pages/CronsPage/CronsPage.vue'),
        },
        {
          path: 'webhook-listeners',
          name: RouteName.WebhookListeners,
          component: () => import('@toolify/client/src/pages/WebhookListenersPage/WebhookListenersPage.vue'),
        },
        {
          path: 'automation-app/:automationAppId',
          name: RouteName.AutomationApp,
          component: () => import('@toolify/client/src/pages/AutomationAppPage/AutomationAppPage.vue'),
        },
        {
          path: 'database/:databaseId',
          // name: RouteName.Database,
          children: [
            {
              path: '',
              name: RouteName.Database,
              component: () => import('../../pages/DatabasePage/DatabasePage.vue'),
              beforeEnter: beforeDatabasePageRouterHookFactory(pinia),
            },
            {
              path: 'v/:databaseViewId',
              name: RouteName.DatabaseView,
              component: () => import('../../pages/DatabaseViewPage/DatabaseViewPage.vue'),
              // beforeEnter: beforeDatabaseViewPageRouterHookFactory(pinia),
            },
            // {
            //   path: 's/:databaseSliceId',
            //   children: [
            //     {
            //       path: '',
            //       name: RouteName.DatabaseSlice,
            //       component: () => import('../../pages/DatabaseSlicePage/DatabaseSlicePage.vue'),
            //       beforeEnter: beforeDatabaseSlicePageRouterHookFactory(pinia),
            //       //on reload
            //     },
            //     {
            //       path: 'v/:databaseViewId',
            //       name: RouteName.DatabaseView,
            //       component: () => import('../../pages/DatabaseViewPage/DatabaseViewPage.vue'),
            //     },
            //   ],
            // },
            {
              path: 'item/:databaseItemId',
              name: RouteName.DatabaseItem,
              component: () => import('../../pages/DatabaseItemPage/DatabaseItemPage.vue'),
            },
            // {
            //   path: 's/:databaseSliceId/v/:databaseViewId',
            //   name: RouteName.DatabaseView,
            //   component: () => import('../../pages/DatabasePage/DatabasePage.vue'),
            // },

          ],
        },
        {
          path: 'conversation/:conversationId',
          name: RouteName.Conversation,
          component: () => import('../../pages/ConversationPage/ConversationPage.vue'),
        },
        {
          path: 'dashboard/:dashboardId',
          name: RouteName.Dashboard,
          component: () => import('../../pages/DashboardPage/DashboardPage.vue'),
        },
        {
          path: 'folder/:folderId',
          name: RouteName.Folder,
          component: () => import('../../pages/FolderPage/FolderPage.vue'),
        },
        {
          path: 'folder/:folderId/catalog/:catalogCloudItemId',
          name: RouteName.CloudCatalog,
          component: () => import('../../pages/CloudItemCatalogPage/CloudItemCatalogPage.vue'),
        },
        {
          path: 'automation/:automationId',
          name: RouteName.Automation,
          component: () => import('../../pages/AutomationPage/AutomationPage.vue'),
        },
        {
          path: 'call/:callId',
          name: RouteName.Call,
          component: () => import('../../pages/CallPage/CallPage.vue'),
        },
      ],
    },
    {
      path: '/@:workspaceName/file/:fileCloudItemId',
      component: () => import('../../views/FilePreviewView.vue'),
      meta: {
        requireWorkspace: true,
        auth: {
          required: true,
        },
        depth: 150,
      },
      children: [
        {
          path: '',
          name: RouteName.CloudFile,
          component: () => import('../../pages/CloudItemFilePage/CloudItemFilePage.vue'),
        },
      ],
    },
    {
      path: '/@:workspaceName/database/:databaseId/item/:databaseItemId/file/:databaseItemFileId',
      component: () => import('../../views/FilePreviewView.vue'),
      meta: {
        requireWorkspace: true,
        auth: {
          required: true,
        },
        depth: 150,
      },
      children: [
        {
          path: '',
          name: RouteName.DatabaseItemFile,
          component: () => import('../../pages/DatabaseFilePage/DatabaseFilePage.vue'),
        },
      ],
    },
    {
      path: '/@:workspaceName/database/:databaseId/item/:databaseItemId/message/:messageId/file/:fileId',
      component: () => import('../../views/FilePreviewView.vue'),
      meta: {
        requireWorkspace: true,
        auth: {
          required: true,
        },
        depth: 150,
      },
      children: [
        {
          path: '',
          name: RouteName.DatabaseCellCommentFile,
          component: () => import('../../pages/DatabaseCellCommentFile/DatabaseCellCommentFilePage.vue'),
        },
      ],
    },
    {
      path: '/@:workspaceName/database/:databaseId/item/:databaseItemId/file/:databaseItemFileId/message/:messageId/file/:fileId',
      component: () => import('../../views/FilePreviewView.vue'),
      meta: {
        requireWorkspace: true,
        auth: {
          required: true,
        },
        depth: 150,
      },
      children: [
        {
          path: '',
          name: RouteName.DatabaseItemFileCommentFile,
          component: () => import('../../pages/DatabaseItemFileCommentFilePage/DatabaseItemFileCommentFilePage.vue'),
        },
      ],
    },
    {
      path: '/@:workspaceName/file/:cloudItemId/message/:messageId/file/:fileId',
      component: () => import('../../views/FilePreviewView.vue'),
      meta: {
        requireWorkspace: true,
        auth: {
          required: true,
        },
        depth: 150,
      },
      children: [
        {
          path: '',
          name: RouteName.CloudItemCommentFile,
          component: () => import('../../pages/CloudItemCommentFilePage/CloudItemCommentFilePage.vue'),
        },
      ],
    },
    {
      path: '/@:workspaceName/message/:messageId/file/:fileId',
      component: () => import('../../views/FilePreviewView.vue'),
      meta: {
        requireWorkspace: true,
        auth: {
          required: true,
        },
        depth: 150,
      },
      children: [
        {
          path: '',
          name: RouteName.ConversationFile,
          component: () => import('../../pages/ConversationFile/ConversationFilePage.vue'),
        },
      ],
    },
    {
      path: '/@:workspaceName/database/:databaseId/settings',
      component: () => import('../../views/DatabaseSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.DatabaseSettings,
          component: () => import('../../pages/DatabaseSettings/DatabaseSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.DatabasePermissions,
          component: () => import('../../pages/DatabaseSettings/DatabasePermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'automations',
          name: RouteName.DatabaseAutomations,
          component: () => import('../../pages/DatabaseSettings/DatabaseAutomationsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'webhooks',
          name: RouteName.DatabaseWebhooks,
          component: () => import('../../pages/DatabaseSettings/DatabaseWebhooksPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'share',
          name: RouteName.DatabaseLinks,
          component: () => import('../../pages/DatabaseSettings/DatabaseLinksPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'activity',
          name: RouteName.DatabaseActivityLog,
          component: () => import('../../pages/DatabaseSettings/DatabaseActivityLogPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/chat/:directConversationId/settings',
      component: () => import('../../views/DirectConversationSettingsView.vue'),
      meta: {
        depth: 150,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.DirectConversationSettings,
          component: () => import('../../pages/ChatPage/DirectConversationSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/conversation/:conversationId/settings',
      component: () => import('../../views/ConversationSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.ConversationSettings,
          component: () => import('../../pages/ConversationSettings/ConversationSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.ConversationPermissions,
          component: () => import('../../pages/ConversationSettings/ConversationPermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/dashboard/:dashboardId/settings',
      component: () => import('../../views/DashboardSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.DashboardSettings,
          component: () => import('../../pages/DashboardSettings/DashboardSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.DashboardPermissions,
          component: () => import('../../pages/DashboardSettings/DashboardPermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/folder/:folderId/settings',
      component: () => import('../../views/FolderSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.FolderSettings,
          component: () => import('../../pages/FolderSettings/FolderSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.FolderPermissions,
          component: () => import('../../pages/FolderSettings/FolderPermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/automation/:automationId/settings',
      component: () => import('../../views/AutomationSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.AutomationSettings,
          component: () => import('../../pages/AutomationSettings/AutomationSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.AutomationPermissions,
          component: () => import('../../pages/AutomationSettings/AutomationPermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/call/:callId/settings',
      component: () => import('../../views/CallSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.CallSettings,
          component: () => import('../../pages/CallSettings/CallSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.CallPermissions,
          component: () => import('../../pages/CallSettings/CallPermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/database/:databaseId/v/:databaseViewId',
      component: () => import('../../views/DatabaseViewSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: 'settings',
          name: RouteName.DatabaseViewSettings,
          component: () => import('../../pages/DatabaseViewSettings/DatabaseViewSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.DatabaseViewPermissions,
          component: () => import('../../pages/DatabaseViewSettings/DatabaseViewPermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'share',
          name: RouteName.DatabaseViewLinks,
          component: () => import('../../pages/DatabaseViewSettings/DatabaseViewLinksPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'activity',
          name: RouteName.DatabaseViewActivityLog,
          component: () => import('../../pages/DatabaseViewSettings/DatabaseViewActivityLogPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/database/:databaseId/s/:databaseSliceId',
      component: () => import('../../views/DatabaseSliceSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: 'settings',
          name: RouteName.DatabaseSliceSettings,
          component: () => import('../../pages/DatabaseSliceSettings/DatabaseSliceSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.DatabaseSlicePermissions,
          component: () => import('../../pages/DatabaseSliceSettings/DatabaseSlicePermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'share',
          name: RouteName.DatabaseSliceLinks,
          component: () => import('../../pages/DatabaseSliceSettings/DatabaseSliceLinksPage.vue'),
          meta: {
            order: 2,
          },
        },
        {
          path: 'activity',
          name: RouteName.DatabaseSliceActivityLog,
          component: () => import('../../pages/DatabaseSliceSettings/DatabaseSliceActivityLogPage.vue'),
          meta: {
            order: 3,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/database/:databaseId/property/:propertyId/settings',
      component: () => import('../../views/DatabasePropertySettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.DatabasePropertySettings,
          component: () => import('../../pages/DatabasePropertySettings/DatabasePropertySettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.DatabasePropertyPermissions,
          component: () => import('../../pages/DatabasePropertySettings/DatabasePropertyPermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'webhooks',
          name: RouteName.DatabasePropertyWebhooks,
          component: () => import('../../pages/DatabasePropertySettings/DatabasePropertyWebhooksPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'activity',
          name: RouteName.DatabasePropertyActivityLog,
          component: () => import('../../pages/DatabasePropertySettings/DatabasePropertyActivityLogPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/@:workspaceName/settings',
      component: () => import('../../views/WorkspaceSettingsView.vue'),
      meta: {
        depth: 150,
        requireWorkspace: true,
        auth: {
          required: true,
        },
      },
      children: [
        {
          path: '',
          name: RouteName.WorkspaceSettings,
          component: () => import('../../pages/WorkspaceSettings/WorkspaceSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: '',
          name: RouteName.WorkspaceUpgradePage,
          component: () => import('../../pages/WorkspaceSettings/WorkspaceUpgradePage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'permissions',
          name: RouteName.WorkspacePermissions,
          component: () => import('../../pages/WorkspaceSettings/WorkspacePermissionsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'webhooks',
          name: RouteName.WorkspaceWebhooks,
          component: () => import('../../pages/WorkspaceSettings/WorkspaceWebhooksPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'invitations',
          name: RouteName.WorkspaceInvitations,
          component: () => import('../../pages/WorkspaceSettings/WorkspaceInvitationsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'groups',
          name: RouteName.WorkspaceGroups,
          component: () => import('../../pages/WorkspaceSettings/WorkspaceGroupsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'members',
          name: RouteName.WorkspaceMembers,
          component: () => import('../../pages/WorkspaceSettings/WorkspaceMembers/WorkspaceMembersPage.vue'),
          meta: {
            order: 2,
          },
        },
        {
          path: 'activity',
          name: RouteName.WorkspaceActivityLog,
          component: () => import('../../pages/WorkspaceSettings/WorkspaceActivityLogPage.vue'),
          meta: {
            order: 1,
          },
        },
      ],
    },
    {
      path: '/settings',
      component: () => import('../../views/UserSettingsView.vue'),
      meta: {
        depth: 150,
        auth: {
          required: true,
        },
      },
      beforeEnter(to, from) {
        const routeStore = useRouteStore(pinia)
        routeStore.setLastRouteNotInSettings(from)
      },
      children: [
        {
          path: '',
          name: RouteName.Settings,
          component: () => import('../../pages/UserSettings/UserSettingsPage.vue'),
          meta: {
            order: 0,
          },
        },
        {
          path: 'appearance',
          name: RouteName.AppearanceSettings,
          component: () => import('../../pages/UserSettings/AppearanceSettingsPage.vue'),
          meta: {
            order: 1,
          },
        },
        {
          path: 'security',
          name: RouteName.AccountSecuritySettings,
          component: () => import('../../pages/UserSettings/AccountSecuritySettingsPage.vue'),
          meta: {
            order: 2,
          },
        },
        {
          path: 'api-keys',
          name: RouteName.ApiKeys,
          component: () => import('../../pages/UserSettings/ApiKeysSettingsPage.vue'),
          meta: {
            order: 3,
          },
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../../views/LandingView.vue'),
      meta: {
        depth: 150,
      },
      children: [
        {
          path: '',
          name: RouteName.NotFound,
          component: () => import('../../pages/NotFoundPage/NotFoundPage.vue'),
          meta: {
            order: 0,
          },
        },
      ],
    },
  ]
}
