import {Pinia} from 'pinia'
import {RouteName} from '@toolify/client/src/setup/router/enum/RouteName'
import {useMeApiStore} from '@toolify/client/src/stores/api/MeApiStore/useMeApiStore'
import UniversalCookie from 'universal-cookie'

export function beforeChooseWorkspaceRouterHookFactory(pinia: Pinia) {
  return async function beforeChooseWorkspaceRouterHook(to) {
    const meApiStore = useMeApiStore()
    const {workspaces} = await meApiStore.getMyWorkspaces()
    if(workspaces.length) {
      const cookies = new UniversalCookie()
      let lastWorkspace = cookies.get('lastWorkspaceName')
      if (!workspaces.find(workspace => workspace.name === lastWorkspace)) {
        lastWorkspace = workspaces[0]?.name
      }
      return {
        name: RouteName.WorkspaceHome,
        params: {
          workspaceName: lastWorkspace,
        },
      }
    }
  }
}
