import {defineStore} from 'pinia'
import {IRouteStoreState} from './types/IRouteStoreState'
import {RouteLocationNormalized} from 'vue-router'

export const useRouteStore = defineStore('routeStore', {
  state: () => {
    return {
      lastRoutePathNotInSettings: null,
    } as IRouteStoreState
  },
  actions: {
    setLastRouteNotInSettings(route: RouteLocationNormalized) {
      if(route.name && !this.lastRoutePathNotInSettings) {
        this.lastRoutePathNotInSettings = route.path
      }
    },
  },
})
