import {INotificationTypeStrategy} from '@toolify/client/src/stores/NotificationStore/types/INotificationTypeStrategy'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {IBrowserNotification} from '@toolify/client/src/stores/NotificationStore/types/IBrowserNotification'
import newNotificationSound from '@toolify/client/src/assets/sounds/new-notification.wav'
import {
  GetMentionedInMessageNotificationTitleByConversationTypeMap,
} from '@toolify/client/src/stores/NotificationStore/maps/GetMentionedInMessageNotificationTitleByConversationTypeMap'

export class MentionedInMessageNotificationStrategy implements INotificationTypeStrategy {
  getBrowserNotification(notification: INotificationResponseEntity<NotificationType.MentionedInMessage>, authorNick: string): IBrowserNotification {
    const title = GetMentionedInMessageNotificationTitleByConversationTypeMap.get(notification.payload.conversationType)(authorNick, notification.payload)
    const body = notification.payload.truncatedContent
    const sound = newNotificationSound
    return {
      sound,
      title,
      body,
    }
  }
}
