import {useAuthStore} from '../../../stores/AuthStore/useAuthStore'
import {Pinia} from 'pinia'
import {useVerificationApiStore} from '@toolify/client/src/stores/api/VerificationApiStore/useVerificationApiStore'
import {
  IMailVerificationStrategyInterfaces,
} from '@toolify/server/src/services/VerificationService/strategies/verificationTypeStrategies/MailVerificationStrategy/types/IMailVerificationStrategyInterfaces'
import {RouteName} from '@toolify/client/src/setup/router/enum/RouteName'

export function beforeVerifyMailAddressRouterHookFactory(pinia: Pinia) {
  return async function beforeVerifyMailAddressRouterHook(to) {
    const verificationApiStore = useVerificationApiStore(pinia)
    const authStore = useAuthStore(pinia)
    try {
      const [verificationId, secret] = to.params.verificationId.split('-')
      const {verification} = await verificationApiStore.fulfillVerification<IMailVerificationStrategyInterfaces>(verificationId, secret)
      const output = verification.output
      await authStore.setTokenWithExpiryDate(output.token, output.expiresAt)
      await authStore.initializeAuth()
      if(to.query.continue) {
        return decodeURIComponent(to.query.continue as string)
      }
      return {
        name: RouteName.ChooseWorkspace,
      }
    } catch(e) {
      // eslint-disable-next-line no-console
      console.log('Failed to fulfill verification', e)
    }
  }
}
