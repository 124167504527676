import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  Router,
  RouteRecordRaw,
} from 'vue-router'
import {isElectron} from '@toolify/client/src/modules/electron/singletons/isElectron'

export function setupRouter(routes: RouteRecordRaw[]): Router {
  let history = createWebHistory()
  if(isElectron()) {
    history = createWebHashHistory()
  }
  return createRouter({
    history,
    routes,
  })
}
