export enum FileVariantType {
  Thumbnail = 'thumbnail',
  Video = 'video',
  Waveform = 'waveform',
  ArchiveContent = 'archive-content',
  Table = 'table',
  Text = 'text',
  Image = 'image',
  Pdf = 'pdf',
}
