import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IUserResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IUserResponseEntity'
import {
  mapDatabaseTimeTrackerItemResponseEntity,
} from '@toolify/client/src/modules/api/singletons/mapDatabaseTimeTrackerItemResponseEntity'

export function mapUserResponseEntity(user: IToJson<IUserResponseEntity>): IUserResponseEntity {
  return {
    ...user,
    activeDatabaseTimeTrackerItem: user?.activeDatabaseTimeTrackerItem ? mapDatabaseTimeTrackerItemResponseEntity(user.activeDatabaseTimeTrackerItem) : null,
  }
}
