import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IResourceItemResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IResourceItemResponseEntity'

export function mapResourceItemResponseEntity(resourceItem: IToJson<IResourceItemResponseEntity>): IResourceItemResponseEntity {
  return {
    ...resourceItem,
    lastActivityAt: resourceItem.lastActivityAt ? new Date(resourceItem.lastActivityAt) : null,
    readAt: resourceItem.readAt ? new Date(resourceItem.readAt) : null,
  } as IResourceItemResponseEntity // TODO: TS: Remove "as ..."
}

