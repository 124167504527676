import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {DatabaseViewType} from '@toolify/server/src/models/mongoose/DatabaseViewModel/enum/DatabaseViewType'
import {IDatabaseFilterQuery} from '@toolify/client/src/modules/databases/types/IDatabaseFilterQuery'
import {
  ICreateViewRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseViewController/validators/CreateView/types/ICreateViewRequestInterfaces'
import {
  IGetViewsRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseViewController/validators/GetViews/types/IGetViewsRequestInterfaces'
import {
  IDeleteViewRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseViewController/validators/DeleteView/types/IDeleteViewRequestInterfaces'
import {
  IUpdateViewRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseViewController/validators/UpdateView/types/IUpdateViewRequestInterfaces'
import {mapDatabaseViewResponseEntity} from '@toolify/client/src/modules/api/singletons/mapDatabaseViewResponseEntity'
import {
  IGetViewRequestInterfaces,
} from '@toolify/server/src/controllers/DatabaseViewController/validators/GetView/types/IGetViewRequestInterfaces'
import {IUpdateDatabaseViewPositionRequestInterfaces} from '../DatabasePropertyApiStore/types/IUpdateDatabaseViewPositionRequestInterfaces'

export const useDatabaseViewApiStore = defineStore('databaseViewApi', {
  actions: {
    async createView(
      databaseId: string,
      type: DatabaseViewType,
      name: string,
      filterQuery?: IDatabaseFilterQuery,
      position?: number,
    ): Promise<ICreateViewRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {databaseView} = await apiRequestStore.post<ICreateViewRequestInterfaces>(`/database/${databaseId}/view`, {
        data: {
          name,
          type,
          filterQuery,
          position,
        },
      })
      return {
        databaseView: mapDatabaseViewResponseEntity(databaseView),
      }
    },

    async getView(
      databaseViewId: string,
    ): Promise<IGetViewRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {databaseView} = await apiRequestStore.get<IGetViewRequestInterfaces>(`/view/${databaseViewId}`)
      return {
        databaseView: mapDatabaseViewResponseEntity(databaseView),
      }
    },

    async updateView(databaseViewId: string, databaseView: IUpdateViewRequestInterfaces['body']): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.patch<IUpdateViewRequestInterfaces>(`/view/${databaseViewId}`, {
        data: databaseView,
      })
    },

    async updateViewPosition(databaseViewId: string, offset: number): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.patch<IUpdateDatabaseViewPositionRequestInterfaces>(`/view/${databaseViewId}/position`, {
        data: {
          offset,
        },
      })
    },

    async deleteView(databaseViewId: string): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.delete<IDeleteViewRequestInterfaces>(`/view/${databaseViewId}`)
    },

    async getViews(databaseId: string): Promise<IGetViewsRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {databaseViews} = await apiRequestStore.get<IGetViewsRequestInterfaces>(`/database/${databaseId}/views`)
      const mappedViews = databaseViews.map(databaseView => {
        return mapDatabaseViewResponseEntity(databaseView)
      })
      return {
        databaseViews: mappedViews,
      }
    },
    async duplicateView(databaseViewId: string): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.post(`/view/${databaseViewId}/duplicate`)
    },
  },
})
