export enum ActivityItemType {
  DatabaseCreated = 'database.created',
  DatabaseUpdated = 'database.updated',
  DatabaseDeleted = 'database.deleted',

  WorkspaceCreated = 'workspace.created',
  WorkspaceDeleted = 'workspace.deleted',
  WorkspaceUpdated = 'workspace.updated',

  DatabasePropertyCreated = 'database.property.created',
  DatabasePropertyDeleted = 'database.property.deleted',
  DatabasePropertyUpdated = 'database.property.updated',
  DatabasePropertyPositionUpdated = 'database.property.position.updated',

  DatabaseItemCreated = 'database.item.created',
  DatabaseItemDeleted = 'database.item.deleted',
  // DatabaseItemValuesUpdated = 'database.item.updated.values',
  DatabaseItemValueUpdated = 'database.item.updated.value',
  DatabaseItemStateUpdated = 'database.item.updated.state',

  DatabaseViewCreated = 'database.view.created',
  DatabaseViewDeleted = 'database.view.deleted',
  DatabaseViewUpdated = 'database.view.updated',
  DatabaseViewPositionUpdated = 'database.view.position.updated',

  DatabaseCellFileCreated = 'database.item.file.created',
  DatabaseCellFileUpdated = 'database.item.file.updated',
  DatabaseCellFileDeleted = 'database.item.file.deleted',

  ResourceItemCreated = 'resource.item.created',
  ResourceItemDeleted = 'resource.item.deleted',
  ResourceItemRenamed = 'resource.item.renamed',
  ResourceItemPositionUpdated = 'resource.item.position.updated',

  MessageCreated = 'message.created',
  MessageDeleted = 'message.deleted',
  MessageUpdated = 'message.updated',

  WorkspaceConversationCreated = 'conversation.created',
  ConversationDeleted = 'conversation.deleted',
  WorkspaceConversationUpdated = 'conversation.updated',

  DashboardItemCreated = 'dashboard.item.created',
  DashboardItemDeleted = 'dashboard.item.deleted',
  DashboardItemUpdated = 'dashboard.item.updated',

  CloudItemCreated = 'cloud.item.created',
  CloudItemDeleted = 'cloud.item.deleted',
  CloudItemUpdated = 'cloud.item.updated',

  DatabaseSliceCreated = 'database.slice.created',
  DatabaseSliceDeleted = 'database.slice.deleted',
  DatabaseSliceUpdated = 'database.slice.updated',
  DatabaseSlicePositionUpdated = 'database.slice.position.updated',

  DatabaseTimeTrackerItemCreated = 'database.time-tracker-item.created',
  DatabaseTimeTrackerItemDeleted = 'database.time-tracker-item.deleted',
  DatabaseTimeTrackerItemUpdated = 'database.time-tracker-item.updated',

  CloudItemCompletedFileVariantCreated = 'cloud.item.completed-file-variant.created',
  DatabaseCellFileCompletedFileVariantCreated = 'database.item.file.completed-file-variant.created',
  MessageCompletedFileVariantCreated = 'message.completed-file-variant.created',
}
