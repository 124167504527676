import {defineStore} from 'pinia'
import {INativeAppStoreState} from './types/INativeAppStoreState'

export const useNativeAppStore = defineStore('nativeAppStore', {
  state: () => {
    return {
      isWindowMaximized: false,
      isWindowInFullscreen: false,
    } as INativeAppStoreState
  },
  actions: {
    async initialize(): Promise<void> {
      if(!window.electronAPI) {
        return
      }
      window.electronAPI.onAppMaximizeStateChange(this.onAppMaximizeStateChange) // TODO: Check if .bind is not needed
      this.isWindowMaximized = await this.getAppFullscreenState()
    },

    dispose(): void {
      if(!window.electronAPI) {
        return
      }
      window.electronAPI.offAppMaximizeStateChange(this.onAppMaximizeStateChange)
    },

    async getAppFullscreenState(): Promise<boolean> {
      return await window.electronAPI.getAppFullscreenState()
    },

    async getAppMaximizeState(): Promise<boolean> {
      return await window.electronAPI.getAppMaximizeState()
    },

    onAppMaximizeStateChange(state: boolean) {
      this.isWindowMaximized = state
    },

    async toggleAppMaximize(): Promise<void> {
      await window.electronAPI.toggleAppMaximizeState(!this.isWindowMaximized)
      // this.isWindowMaximized = await this.getAppMaximizeState()
    },

    async toggleAppFullscreen(): Promise<void> {
      await window.electronAPI.toggleAppFullscreen(!this.isWindowInFullscreen)
      this.isWindowInFullscreen = await this.getAppFullscreenState()
    },

    async openDevTools(): Promise<void> {
      await window.electronAPI.openDevTools()
    },

    async minimizeApp(): Promise<void> {
      await window.electronAPI.minimizeApp()
    },

    async exitApp(): Promise<void> {
      await window.electronAPI.exitApp()
    },

    async updateNotificationCount(count: number): Promise<void> {
      await window.electronAPI.updateNotificationCount(count)
    },
  },
})
