import {Pinia} from 'pinia'
import {RouteLocationNormalized} from 'vue-router'

export function beforeDatabaseViewPageRouterHookFactory(pinia: Pinia) {
  return async function beforeDatabaseViewPageRouterHook(to: RouteLocationNormalized) {
    const databaseId = to.params.databaseId as string
    const viewId = to.params.databaseViewId as string
    if(!databaseId || !viewId) {
      return
    }
    window.localStorage.setItem(`current-view-db-${databaseId}`, viewId)
  }
}
