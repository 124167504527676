import {useAuthStore} from '../../../stores/AuthStore/useAuthStore'
import {Pinia} from 'pinia'

export function beforeLinkPageEnterRouterHookFactory(pinia: Pinia) {
  return async function beforeLinkPageEnterRouterHook(to) {
    // await new Promise(resolve => {
    //   setTimeout(resolve, 10000)
    // })
    const authStore = useAuthStore(pinia)
    authStore.$patch({
      currentLinkId: to.params.linkId,
    })
    // try {
    //   const [verificationId, secret] = to.params.verificationId.split('-')
    //   const {verification} = await verificationApiStore.fulfillVerification<IMailVerificationStrategyInterfaces>(verificationId, secret)
    //   const output = verification.output
    //   await authStore.setTokenWithExpiryDate(output.token, output.expiresAt)
    //   await authStore.initializeAuth()
    //   if(to.query.continue) {
    //     return decodeURIComponent(to.query.continue as string)
    //   }
    //   return {
    //     name: RouteName.ChooseWorkspace,
    //   }
    // } catch(e) {
    //   console.log('Failed to fulfill verification')
    // }
  }
}
