import {useSocketStore} from '@toolify/client/src/stores/SocketStore/useSocketStore'
import {onBeforeUnmount, onMounted, watch} from 'vue'

export function useSocketListener<PayloadType = unknown>(
  name: string,
  fn: (payload: PayloadType) => void,
  options?: {
    useLifecycleHooks?: boolean
    lifecycleFn?: () => boolean
  },
) {
  const socketStore = useSocketStore()

  function start() {
    socketStore.on(name, fn as (payload: PayloadType) => void)
  }

  function stop() {
    socketStore.off(name, fn as (payload: PayloadType) => void)
  }
  if(options?.useLifecycleHooks !== false && !options?.lifecycleFn) {
    onMounted(() => {
      start()
    })

    onBeforeUnmount(() => {
      stop()
    })
  }

  watch(() => {
    return options?.lifecycleFn?.()
  }, (value, oldValue) => {
    if(!options?.lifecycleFn) {
      return
    }
    if(value === oldValue) {
      return
    }
    if(value) {
      return start()
    }
    stop()
  }, {
    immediate: true,
  })

  return {
    start,
    stop,
  }
}
