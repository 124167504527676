import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'

import {
  ICreateDirectConversationRequestInterfaces,
} from '@toolify/server/src/controllers/ChatController/validators/CreateDirectConversation/types/ICreateDirectConversationRequestInterfaces'
import {IEntity} from '@toolify/server/src/services/EntityService/types/IEntity'
import {
  IGetDirectConversationRequestInterfaces,
} from '@toolify/server/src/controllers/ChatController/validators/GetDirectConversation/types/IGetDirectConversationRequestInterfaces'
import {
  mapDirectConversationResponseEntity,
} from '@toolify/client/src/modules/api/singletons/mapDirectConversationResponseEntity'
import {
  IGetDirectConversationsRequestInterfaces,
} from '@toolify/server/src/controllers/ChatController/validators/GetDirectConversations/types/IGetDirectConversationsRequestInterfaces'

export const useDirectConversationApiStore = defineStore('directConversationApiStore', {
  actions: {
    async createDirectConversation(
      entities: IEntity[],
    ): Promise<ICreateDirectConversationRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {directConversation} = await apiRequestStore.post<ICreateDirectConversationRequestInterfaces>('/direct-conversation', {
        data: {
          entities,
        },
      })
      return {
        directConversation: mapDirectConversationResponseEntity(directConversation),
      }
    },

    async getDirectConversation(
      directConversationId: string,
    ): Promise<IGetDirectConversationRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {directConversation} = await apiRequestStore.get<IGetDirectConversationRequestInterfaces>(`/direct-conversation/${directConversationId}`)
      return {
        directConversation: mapDirectConversationResponseEntity(directConversation),
      }
    },

    async getDirectConversations(): Promise<IGetDirectConversationsRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {directConversations} = await apiRequestStore.get<IGetDirectConversationsRequestInterfaces>('/direct-conversations')
      return {
        directConversations: directConversations.map(directConversation => {
          return mapDirectConversationResponseEntity(directConversation)
        }),
      }
    },

    async updateDirectConversationName(
      directConversationId: string,
      name: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put(`/direct-conversation/${directConversationId}/name`, {
        data: {
          name,
        },
      })
    },
  },
})
