import {useRoute, useRouter} from 'vue-router'
import {useActivityItemSocketListener} from '@toolify/client/src/composables/useActivityItemSocketListener'
import {ActivityItemType} from '@toolify/server/src/models/mongoose/ActivityItemModel/enum/ActivityItemType'
import {mapWorkspaceResponseEntity} from '@toolify/client/src/modules/api/singletons/mapWorkspaceResponseEntity'
import {useWorkspaceStore} from '@toolify/client/src/stores/WorkspaceStore/useWorkspaceStore'

export function useWorkspaceSocketHooks() {
  const workspaceStore = useWorkspaceStore()
  const route = useRoute()
  const router = useRouter()

  useActivityItemSocketListener(ActivityItemType.WorkspaceUpdated, ({payload, targetResource}) => {
    const foundWorkspace = workspaceStore.myWorkspaces?.find(workspace => workspace.id === targetResource.resourceId)
    if(foundWorkspace) {
      workspaceStore.myWorkspaces = workspaceStore.myWorkspaces.map(workspace => {
        if(workspace.id !== targetResource.resourceId) {
          return workspace
        }
        return mapWorkspaceResponseEntity(payload.currentWorkspace)
      })
    }
    if(workspaceStore.currentWorkspace && workspaceStore.currentWorkspace.id === targetResource.resourceId) {
      workspaceStore.currentWorkspace = mapWorkspaceResponseEntity(payload.currentWorkspace)
      if(payload.previousWorkspace.name !== payload.currentWorkspace.name) {
        router.push({
          name: route.name,
          params: {
            ...route.params,
            workspaceName: payload.currentWorkspace.name,
          },
        })
      }

    }
  })
}
