<script setup lang="ts">

import AppContent from '@toolify/client/src/modules/app/components/AppContent.vue'
import {isElectron} from '@toolify/client/src/modules/electron/singletons/isElectron'
import NativeWindowHeaderComponent
  from '@toolify/client/src/modules/electron/components/NativeWindowHeaderComponent.vue'
import NativeAppUpdateBarComponent
  from '@toolify/client/src/modules/electron/components/NativeAppUpdateBarComponent.vue'
import {useAppUpdateStore} from '@toolify/client/src/stores/AppUpdateStore/useAppUpdateStore'
import {useMeta} from 'vue-meta'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'
import {$computed} from 'vue/macros'
import {
  ThemeType,
} from '@toolify/server/src/services/UserSettingsService/strategies/ThemeUserSettingStrategy/enum/ThemeType'

const hasNativeHeader = isElectron()
const appUpdateStore = useAppUpdateStore()
const authStore = useAuthStore()

const currentTheme = $computed(() => {
  // return authStore.currentUser?.settings?.theme || ThemeType.Dark
  return ThemeType.FiordDark
})
useMeta({
  title: '',
  htmlAttrs: {
    lang: 'en',
    amp: true,
  },
})

const appBrandingName = ENV.AppBrandingName

</script>

<template>
  <Metainfo>
    <template #title="{ content }">
      {{content ? `${content} - ${appBrandingName}` : `${appBrandingName} - Most flexible CRM in the world`}}
    </template>
  </Metainfo>
  <div
    class="app-wrapper"
    :class="{'has-native-header': hasNativeHeader, 'show-app-update-bar': appUpdateStore.isUpdateAvailable}"
    :data-theme="currentTheme"
  >
    <div class="floating-container" id="tooltip-divs"/>
    <div class="floating-container" id="dropdown-divs"/>
    <div id="teleported"/>
    <NativeWindowHeaderComponent v-if="hasNativeHeader"/>
    <NativeAppUpdateBarComponent v-if="appUpdateStore.isUpdateAvailable"/>
    <AppContent/>
  </div>
</template>

<style scoped lang="scss">
@use '../styles/variables' as variables;
.app-wrapper {
  width: 100%;
  height: 100%;
  color: map-get(variables.$colors, 'content-normal');
  background: map-get(variables.$colors, 'surface');

  &.has-native-header {
    display: grid;
    grid-template-rows: auto 1fr;

    &.show-app-update-bar {
      grid-template-rows: auto auto 1fr;
    }
  }
}

.floating-container {
  position: absolute;
  left: 20px;
  top: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  overflow: hidden;

  .app-wrapper.has-native-header & {
    top: calc(20px + 32px);
    height: calc(100% - 40px - 32px);
  }

  .app-wrapper.show-app-update-bar & {
    top: calc(20px + 32px + 32px);
    height: calc(100% - 40px - 32px - 32px);
  }
}
</style>
