import {App} from 'vue'
import FloatingVue from 'floating-vue'
import '../styles/floating-vue-style.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import {createMetaManager} from 'vue-meta'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import {i18n} from '../setup/i18n'

export function setupVuePlugins(vueApp: App): void {
  const floatingVueConfig = {
    themes: {
      tooltip: {
        delay: {
          show: 0,
          hide: 0,
        },
      },
    },
  }

  const metaManager = createMetaManager()
  vueApp.use(i18n)
  vueApp.use(FloatingVue, floatingVueConfig)
  vueApp.use(metaManager)
  vueApp.use(ToastPlugin)
}
