import {defineStore} from 'pinia'
import {IAppStoreState} from './types/IAppStoreState'
import {useKeyboardShortcutStore} from '../KeyboardShortcutStore/useKeyboardShortcutStore'
import {useSocketStore} from '@toolify/client/src/stores/SocketStore/useSocketStore'
import {useAppUpdateStore} from '@toolify/client/src/stores/AppUpdateStore/useAppUpdateStore'
import {useApiRequestStore} from '@toolify/client/src/stores/ApiRequestStore/useApiRequestStore'
import {useNativeAppStore} from '@toolify/client/src/stores/AppTitlebarStore/useNativeAppStore'
import {useNotificationStore} from '@toolify/client/src/stores/NotificationStore/useNotificationStore'
import {useWorkspaceStore} from '@toolify/client/src/stores/WorkspaceStore/useWorkspaceStore'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'
import {useResourceItemStore} from '@toolify/client/src/stores/ResourceItemStore/useResourceItemStore'
import {useDirectConversationStore} from '@toolify/client/src/stores/DirectConversationStore/useDirectConversationStore'

export const useAppStore = defineStore('appStore', {
  state: () => {
    return {
      isAppLoaded: false,
      isFirstRouteInitialized: false,
      isPageLoading: true,
      pageLoadingPromise: null,
    } as IAppStoreState
  },
  getters: {
    shouldShowPageLoadingBar(state) {
      return state.isAppLoaded && state.isPageLoading
    },
  },
  actions: {
    async initializeAppIfNotInitialized(): Promise<void> {
      if(this.isAppLoaded) {
        return
      }
      await this.initializeApp()
    },

    async initializeApp(): Promise<void> {
      const authStore = useAuthStore()
      const keyboardShortcutStore = useKeyboardShortcutStore()
      const socketStore = useSocketStore()
      const appUpdateStore = useAppUpdateStore()
      const apiRequestStore = useApiRequestStore()
      const nativeAppStore = useNativeAppStore()
      const notificationStore = useNotificationStore()
      const workspaceStore = useWorkspaceStore()
      const resourceItemStore = useResourceItemStore()
      const directConversationStore = useDirectConversationStore()
      keyboardShortcutStore.initializeListeners()
      socketStore.initialize()
      authStore.initialize()
      appUpdateStore.initialize()
      apiRequestStore.initialize()
      notificationStore.initialize()
      workspaceStore.initialize()
      resourceItemStore.initialize()
      await directConversationStore.initialize()
      await nativeAppStore.initialize()
      this.isAppLoaded = true
    },

    disposeApp(): void {
      const authStore = useAuthStore()
      const keyboardShortcutStore = useKeyboardShortcutStore()
      const socketStore = useSocketStore()
      const appUpdateStore = useAppUpdateStore()
      const apiRequestStore = useApiRequestStore()
      const nativeAppStore = useNativeAppStore()
      const notificationStore = useNotificationStore()
      const workspaceStore = useWorkspaceStore()
      const resourceItemStore = useResourceItemStore()
      const directConversationStore = useDirectConversationStore()
      keyboardShortcutStore.dispose()
      socketStore.dispose()
      authStore.dispose()
      appUpdateStore.dispose()
      apiRequestStore.dispose()
      notificationStore.dispose()
      workspaceStore.dispose()
      resourceItemStore.dispose()
      nativeAppStore.dispose()
      directConversationStore.dispose()
      this.isAppLoaded = false
      this.isFirstRouteInitialized = false
    },
  },
})
