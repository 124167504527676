import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IDatabaseTimeTrackerItemResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IDatabaseTimeTrackerItemResponseEntity'
import {mapAuthorResponseEntity} from '@toolify/client/src/modules/api/singletons/mapAuthorResponseEntity'

export function mapDatabaseTimeTrackerItemResponseEntity(
  databaseTimeTrackerItem: IToJson<IDatabaseTimeTrackerItemResponseEntity>,
): IDatabaseTimeTrackerItemResponseEntity {
  return {
    ...databaseTimeTrackerItem,
    startedAt: new Date(databaseTimeTrackerItem.startedAt),
    endedAt: databaseTimeTrackerItem.endedAt ? new Date(databaseTimeTrackerItem.endedAt) : null,
    authorEntity: databaseTimeTrackerItem.authorEntity ? mapAuthorResponseEntity(databaseTimeTrackerItem.authorEntity) : null,
  }
}
