export enum RouteName {
  ApiKeys = 'api-keys',
  Login = 'login',
  Register = 'register',
  WorkspaceSettings = 'workspace-settings',
  WorkspaceActivityLog = 'workspace-activity-log',
  WorkspacePermissions = 'workspace-permissions',
  WorkspaceMembers = 'workspace-members',
  WorkspaceHome = 'workspace-home',
  WorkspaceInvitations = 'workspace-invitations',
  Database = 'database',
  DatabaseView = 'DatabaseView',
  WorkspaceGroups = 'workspace-groups',
  WorkspaceWebhooks = 'workspace-webhooks',
  DatabaseSettings = 'database-settings',
  DatabaseAutomations = 'database-automations',
  DatabaseActivityLog = 'database-activity-log',
  DatabasePermissions = 'database-permissions',
  DatabaseWebhooks = 'database-webhooks',
  DatabasePropertySettings = 'database-property-settings',
  DatabasePropertyPermissions = 'database-property-permissions',
  DatabasePropertyWebhooks = 'database-property-webhooks',
  Settings = 'settings',
  AppearanceSettings = 'appearance-settings',
  VerifyMailAddress = 'verify-mail-address',
  ChooseWorkspace = 'choose-workspace',
  Logout = 'logout',
  CreateWorkspace = 'create-workspace',
  Invitation = 'invitation',
  NotFound = 'not-found',
  AccountSecuritySettings = 'account-security-settings',
  TwoFactorLogin = 'two-factor-login',
  ForgotPassword = 'forgot-password',
  DatabaseItemFile = 'database-item-file',
  ResetPassword = 'reset-password',
  DatabasePropertyActivityLog = 'database-property-activity-log',
  DatabaseLinks = 'database-links',
  DatabaseViewSettings = 'database-view-settings',
  DatabaseViewActivityLog = 'database-view-activity-log',
  DatabaseViewPermissions = 'database-view-permissions',
  DatabaseViewLinks = 'database-view-links',
  DatabaseSliceSettings = 'database-slice-settings',
  DatabaseSliceActivityLog = 'database-slice-activity-log',
  DatabaseSlicePermissions = 'database-slice-permissions',
  DatabaseSliceLinks = 'database-slice-links',
  Conversation = 'conversation',
  ConversationSettings = 'conversation-settings',
  ConversationPermissions = 'conversation-permissions',
  ConversationFile = 'conversation-file',
  Dashboard = 'dashboard',
  DashboardSettings = 'dashboard-settings',
  DashboardPermissions = 'dashboard-permissions',
  Call = 'call',
  CallSettings = 'call-settings',
  CallPermissions = 'call-permissions',
  Automation = 'automation',
  Folder = 'folder',
  AutomationSettings = 'automation-settings',
  AutomationPermissions = 'automation-permissions',
  FolderSettings = 'folder-settings',
  FolderPermissions = 'folder-permissions',
  CloudCatalog = 'cloud-catalog',
  CloudFile = 'cloud-file',
  DatabaseItem = 'database-item',
  DatabaseSlice = 'database-slice',
  DatabaseCellCommentFile = 'database-cell-comment-file',
  CloudItemCommentFile = 'cloud-item-comment-file',
  DatabaseItemFileCommentFile = 'database-item-file-comment-file',
  WorkspaceUpgradePage = 'workspace-upgrade-page',
  CreateDirectConversation = 'create-direct-conversation',
  DirectConversation = 'direct-conversation',
  DirectConversationSettings = 'direct-conversation-settings',
  AutomationApps = 'automation-apps',
  AutomationApp = 'automation-app',
  Crons = 'crons',
  WebhookListeners = 'webhook-listeners',
}

