import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IDatabaseViewResponseEntity,
} from '@toolify/server/src/controllers/DatabaseViewController/types/IDatabaseViewResponseEntity'

export function mapDatabaseViewResponseEntity(databaseView: IToJson<IDatabaseViewResponseEntity>): IDatabaseViewResponseEntity {
  return {
    ...databaseView,
    createdAt: new Date(databaseView.createdAt),
    updatedAt: new Date(databaseView.updatedAt),
  }
}
