import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {mapResourceSpaceResponseEntity} from '@toolify/client/src/modules/api/singletons/mapResourceSpaceResponseEntity'
import {IUpdateResourceSpaceNameRequestInterfaces} from '@toolify/server/src/controllers/ResourceSpaceController/validators/UpdateResourceSpaceName/types/IUpdateResourceSpaceNameRequestInterfaces'
import {ICreateResourceSpaceRequestInterfaces} from '@toolify/server/src/controllers/ResourceSpaceController/validators/CreateResourceSpace/types/ICreateResourceSpaceRequestInterfaces'
import {IGetResourceSpacesRequestInterfaces} from '@toolify/server/src/controllers/ResourceSpaceController/validators/GetResourceSpace/types/IGetResourceSpacesRequestInterfaces'
import {IDeleteResourceSpaceRequestInterfaces} from '@toolify/server/src/controllers/ResourceSpaceController/validators/DeleteResourceSpace/types/IDeleteResourceSpaceRequestInterfaces'

export const useResourceSpaceApiStore = defineStore('resourceSpaceApi', {
  actions: {
    async getResourceSpaces(
      workspaceId: string,
    ): Promise<IGetResourceSpacesRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {resourceSpaces} = await apiRequestStore.get<IGetResourceSpacesRequestInterfaces>(`/workspace/${workspaceId}/resource-spaces`)
      return {
        resourceSpaces: resourceSpaces.map(activityItem => mapResourceSpaceResponseEntity(activityItem)),
      }
    },

    async createResourceSpace(
      workspaceId: string,
      name?: string,
    ): Promise<ICreateResourceSpaceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {resourceSpace} = await apiRequestStore.post<ICreateResourceSpaceRequestInterfaces>(`/workspace/${workspaceId}/resource-space`, {
        data: {
          name,
        },
      })
      return {
        resourceSpace: mapResourceSpaceResponseEntity(resourceSpace),
      }
    },

    async updateResourceSpaceName(
      resourceSpaceId: string,
      name: string,
    ): Promise<ICreateResourceSpaceRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {resourceSpace} = await apiRequestStore.put<IUpdateResourceSpaceNameRequestInterfaces>(`/resource-space/${resourceSpaceId}/name`, {
        data: {
          name,
        },
      })
      return {
        resourceSpace: mapResourceSpaceResponseEntity(resourceSpace),
      }
    },

    async deleteResourceSpace(
      resourceSpaceId: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.delete<IDeleteResourceSpaceRequestInterfaces>(`/resource-space/${resourceSpaceId}`)
    },
  },
})
