export enum LocaleType {
  Polish = 'pl',
  English = 'en',
  German = 'de',
  Ukrainian = 'ua',
  French = 'fr',
  Spanish = 'es',
  Italian = 'it',
  Portuguese = 'pt',
  Dutch = 'nl',
  Swedish = 'sv',
  Norwegian = 'no',
  Danish = 'da',
  Finnish = 'fi',
  Greek = 'el',
  Turkish = 'tr',
  Japanese = 'ja',
  Chinese = 'zh',
  Korean = 'ko',
  Arabic = 'ar',
}
