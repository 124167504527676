import {INotificationTypeStrategy} from '@toolify/client/src/stores/NotificationStore/types/INotificationTypeStrategy'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {IBrowserNotification} from '@toolify/client/src/stores/NotificationStore/types/IBrowserNotification'
import newNotificationSound from '@toolify/client/src/assets/sounds/new-notification.wav'

export class ExportedDatabaseNotificationStrategy implements INotificationTypeStrategy {
  getBrowserNotification(notification: INotificationResponseEntity<NotificationType.ExportedDatabase>, authorNick: string): IBrowserNotification {
    const title = `${authorNick}, your database export has finished!}`
    const body = 'Your requested database export has finished. You can find your file in browser downloads folder'
    const sound = newNotificationSound
    const {fileString, mimeType, fileName} = notification.payload
    const a = document.createElement('a')
    a.href = `data:${mimeType};base64,${fileString}`
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    return {
      sound,
      title,
      body,
    }
  }
}
