import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {
  IMeRequestInterfaces,
} from '@toolify/server/src/controllers/UserController/validators/Me/types/IMeRequestInterfaces'
import {
  IGetMyWorkspacesRequestInterfaces,
} from '@toolify/server/src/controllers/UserController/validators/GetMyWorkspaces/types/IGetMyWorkspacesRequestInterfaces'
import {UserSettingName} from '@toolify/server/src/models/mongoose/UserModel/enum/UserSettingName'
import {
  IUpdateUserSettingRequestInterfaces,
} from '@toolify/server/src/controllers/UserController/validators/UpdateUserSettings/types/IUpdateUserSettingRequestInterfaces'
import {
  IUpdateUserNickRequestInterfaces,
} from '@toolify/server/src/controllers/UserController/validators/UpdateUserNick/types/IUpdateUserNickRequestInterfaces'
import {UserStatusType} from '@toolify/server/src/models/mongoose/UserModel/enum/UserStatusType'
import {
  IUpdateUserStatusRequestInterfaces,
} from '@toolify/server/src/controllers/UserController/validators/UpdateUserStatus/types/IUpdateUserStatusRequestInterfaces'
import {mapUserResponseEntity} from '@toolify/client/src/modules/api/singletons/mapUserResponseEntity'
import {
  IGetMembersByAllParentResourcesRequestInterfaces,
} from '@toolify/server/src/controllers/PermissionController/validators/GetMembersByAllParentResources/types/IGetMembersByAllParentResourcesRequestInterfaces'

export const useMeApiStore = defineStore('meApi', {
  actions: {
    async me(): Promise<IMeRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {user} = await apiRequestStore.get<IMeRequestInterfaces>('/me', {
      }, {
        priority: 1,
      })
      return {
        user: mapUserResponseEntity(user),
      }
    },

    async getMyWorkspaces(): Promise<IGetMyWorkspacesRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.get<IGetMyWorkspacesRequestInterfaces>('/me/workspaces')
    },

    async updateSetting(settingName: UserSettingName, value: unknown): Promise<IUpdateUserSettingRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      return await apiRequestStore.put<IUpdateUserSettingRequestInterfaces>(`/me/setting/${settingName}`, {
        data: {
          value,
        },
      })
    },

    async updateNick(nick: string): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put<IUpdateUserNickRequestInterfaces>('/me/nick', {
        data: {
          nick,
        },
      })
    },

    async updateStatus(statusText: string, statusType: UserStatusType): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put<IUpdateUserStatusRequestInterfaces>('/me/status', {
        data: {
          statusText,
          statusType,
        },
      })
    },

    async updateAvatar(file: File|Blob): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      const formData = new FormData()
      formData.append('file', file)
      await apiRequestStore.put('/me/avatar', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
    },

    async updateCover(file: File|Blob): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      const formData = new FormData()
      formData.append('file', file)
      await apiRequestStore.put('/me/cover', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
    },

    async getFriends(): Promise<IGetMembersByAllParentResourcesRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {users} = await apiRequestStore.get<IGetMembersByAllParentResourcesRequestInterfaces>('/me/friends')
      return {
        users: users.map(user => {
          return mapUserResponseEntity(user)
        }),
      }
    },
  },
})
