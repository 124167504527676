import {INotificationTypeStrategy} from '@toolify/client/src/stores/NotificationStore/types/INotificationTypeStrategy'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {IBrowserNotification} from '@toolify/client/src/stores/NotificationStore/types/IBrowserNotification'
import newMessageSound from '@toolify/client/src/assets/sounds/new-message.wav'

import {
  GetNewMessageNotificationTitleByConversationTypeMap,
} from '@toolify/client/src/stores/NotificationStore/maps/GetNewMessageNotificationTitleByConversationTypeMap'

export class NewMessageNotificationStrategy implements INotificationTypeStrategy {
  getBrowserNotification(notification: INotificationResponseEntity<NotificationType.NewMessage>, authorNick: string): IBrowserNotification {
    const title = GetNewMessageNotificationTitleByConversationTypeMap.get(notification.payload.conversationType)(authorNick, notification.payload)
    const body = notification.payload.truncatedContent
    const sound = newMessageSound
    return {
      sound,
      title,
      body,
    }
  }
}
