import {Pinia} from 'pinia'

export function preventNavigationRouterHookFactory(pinia: Pinia) {
  return async function preventNavigationRouterHook(to, from) {
    const canLeaveRouteFunction = from.meta?.canLeaveRoute
    if(canLeaveRouteFunction && !canLeaveRouteFunction(pinia)) {
      return false
    }
  }
}
