import {ConversationType} from '@toolify/server/src/models/mongoose/MessageModel/enum/ConversationType'
import {
  INewMessageNotificationTypeInterfaces,
} from '@toolify/server/src/services/NotificationService/strategies/INewMessageNotificationTypeInterfaces'

export const GetNewMessageNotificationTitleByConversationTypeMap = new Map<ConversationType,(authorNick: string, payload: INewMessageNotificationTypeInterfaces['payload']) => string>([
  [ConversationType.Workspace, (authorNick: string, payload: INewMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as INewMessageNotificationTypeInterfaces<ConversationType.Workspace>['payload']
    return `${authorNick} wrote a message in ${typedPayload.conversationNotificationPayload.workspaceConversationName}`
  }],
  [ConversationType.CloudFile, (authorNick: string, payload: INewMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as INewMessageNotificationTypeInterfaces<ConversationType.CloudFile>['payload']
    return `${authorNick} commented on ${typedPayload.conversationNotificationPayload.cloudItemName}`
  }],
  [ConversationType.DatabaseCell, (authorNick: string, payload: INewMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as INewMessageNotificationTypeInterfaces<ConversationType.DatabaseCell>['payload']
    return `${authorNick} added a comment on ${typedPayload.conversationNotificationPayload.databaseItemName || 'Untitled item'} / ${typedPayload.conversationNotificationPayload.databasePropertyName}`
  }],
  [ConversationType.DatabaseFile, (authorNick: string, payload: INewMessageNotificationTypeInterfaces['payload']) => {
    const typedPayload = payload as INewMessageNotificationTypeInterfaces<ConversationType.DatabaseFile>['payload']
    return `${authorNick} commented on file ${typedPayload.conversationNotificationPayload.databaseItemFileName} in ${typedPayload.conversationNotificationPayload.databaseItemName  || 'Untitled item'}`
  }],
])
