import {onBeforeUnmount, onMounted, watch} from 'vue'
import {useKeyboardShortcutStore} from '@toolify/client/src/stores/KeyboardShortcutStore/useKeyboardShortcutStore'
import {$ref} from 'vue/macros'
import {IKeyboardShortcut} from '@toolify/client/src/stores/KeyboardShortcutStore/types/IKeyboardShortcut'

export function useKeyboardShortcuts(
  shortcuts: [
    IKeyboardShortcut['keys'],
    IKeyboardShortcut['callback']
  ][],
  rule?: () => boolean,
) {
  const scope = Symbol()
  const keyboardShortcutStore = useKeyboardShortcutStore()
  let shortcutIds = $ref<symbol[]>([])

  onMounted(() => {
    if(!rule || rule()) {
      registerShortcuts()
    }
  })

  onBeforeUnmount(() => {
    unregisterShortcuts()
  })

  const registerShortcuts = () => {
    keyboardShortcutStore.createScope(scope)
    shortcutIds = keyboardShortcutStore.registerShortcuts(shortcuts.map(shortcut => {
      return {
        keys: shortcut[0],
        callback: shortcut[1],
      }
    }), scope)
  }

  const unregisterShortcuts = () => {
    keyboardShortcutStore.deleteShortcutsByIds(shortcutIds)
    keyboardShortcutStore.deleteScope(scope)
    shortcutIds = []
  }

  if(rule) {
    watch(rule, (value) => {
      if(value) {
        registerShortcuts()
      } else {
        unregisterShortcuts()
      }
    })
  }

  return {
    registerShortcuts,
    unregisterShortcuts,
  }
}
