<script setup lang="ts">
import LogoComponent from '@toolify/client/src/modules/branding/LogoComponent.vue'
import {useNativeAppStore} from '@toolify/client/src/stores/AppTitlebarStore/useNativeAppStore'
import {useAppUpdateStore} from '@toolify/client/src/stores/AppUpdateStore/useAppUpdateStore'

// interface Emits {
//   (e: 'event1', payload: string): void
// }
//
// interface Props {
//
// }
//
// const props = withDefaults(defineProps<Props>(), {})
//
// const emit = defineEmits<Emits>()

const nativeAppStore = useNativeAppStore()
const appUpdateStore = useAppUpdateStore()
</script>

<template>
  <div class="native-window-header">
    <div class="logos">
      <!--      <div class="logo-icon-wrapper">-->
      <!--        <LogoComponent isShort isWhite/>-->
      <!--      </div>-->
      <LogoComponent isWhite/>
    </div>
    <div class="buttons">
      <div class="button" v-if="appUpdateStore.isUpdateAvailable" @click="appUpdateStore.downloadAppUpdate()">
        <i class="far fa-arrow-down"/>
      </div>
      <div class="button" v-if="appUpdateStore.isUpdateDownloaded" @click="appUpdateStore.restartAppAndUpdate()">
        <i class="far fa-refresh"/>
      </div>
      <div class="button" @click="nativeAppStore.openDevTools()">
        <i class="far fa-square-terminal"/>
      </div>
      <div class="button" @click="nativeAppStore.minimizeApp()">
        <i class="far fa-minus"/>
      </div>
      <div class="button" @click="nativeAppStore.toggleAppMaximize()">
        <i class="far" :class="[nativeAppStore.isWindowMaximized ? 'far fa-compress' : 'far fa-expand']"/>
      </div>
      <div class="button" @click="nativeAppStore.exitApp()">
        <i class="far fa-xmark"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../../styles/variables' as variables;

.native-window-header {
  height: 32px;
  background: map-get(variables.$colors, surface);
  z-index: 999999;
  -webkit-app-region: drag;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logos {
  margin-left: 10px;
  display: flex;
  gap: 10px;
}
.logo {
  font-size: 16px;
  opacity: 0.75;
}

.logo-icon-wrapper {
  background: map-get(variables.$colors, primary);
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.buttons {
  display: flex;
  height: 100%;
  -webkit-app-region: no-drag;
}

.button {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsla(0, 0%, 100%, 0.75);
  height: 100%;

  &:hover {
    background: hsla(0, 0%, 100%, 0.1);
    color: hsla(0, 0%, 100%, 0.9);

    & i {
      transform: scale(1.1);
    }
  }

  &:last-child {
    padding-right: 2px;
    &:hover {
      background: map-get(variables.$colors, error);
    }

  }
  i {
    font-size: 14px;
  }
}
</style>
